/* global BigInt */
import React, { useState } from "react";
import Modal from "react-modal";
import heroButtonBg from "../../assets/default/Frame-1.png";
import { formatUnits, parseUnits } from "ethers/lib/utils";
// Modal.setAppElement("#yourAppElement");
import { LaunchpadAddress } from "../../config";
import Loader from "../Loader/Loader";
import {
  useApproveCallback,
  ApprovalState,
} from "../../hooks/useApproveCallback";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import { useTransactionAdder } from "../../state/transactions/hooks";
import { useGasPrice } from "../../state/user/hooks";
import { calculateGasMargin, getLaunchPadContract } from "../../utils";

Modal.setAppElement("#root");

function MintModal({ poolId, pool, tokenDecimals, isModelOpen, closeModalFn }) {
  function closeModal() {
    closeModalFn();
  }

  const [showLoading, setShowLoading] = useState(false);
  const { account, chainId, library } = useActiveWeb3React();
  const gasPrice = useGasPrice();

  const [approval, approveCallback] = useApproveCallback(
    pool?.tokenTotalSupply,
    pool?.tokenAddress,
    LaunchpadAddress
  );

  const supplyHandle = async () => {
    const ticketTokenContract = getLaunchPadContract(library, account);

    const estimate = ticketTokenContract.estimateGas.issueToken;
    const method = ticketTokenContract.issueToken;

    const args = [poolId];
    const value = null;

    await estimate(...args, value ? { value } : {})
      .then((estimatedGasLimit) => {
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit),
          gasPrice,
        }).then((response) => {
          // setAttemptingTxn(false);
          closeModalFn();
        });
      })
      .catch((err) => {
        // setAttemptingTxn(false);
        // we only care if the error is something _other_ than the user rejected the tx
        if (err?.code !== 4001) {
          console.error(err);
        }
      });
  };

  return (
    <div>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={isModelOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            width: "60%",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        contentLabel="Example Modal"
      >
        {/* {showLoading && <Loader />} */}
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Update</h2> */}
        <div className="custom-modal-content" style={{ minHeight: 120 }}>
          {approval == ApprovalState.PENDING || showLoading ? (
            <Loader />
          ) : (
            <div className="form-form">
              <div className="mb-0">
                <label
                  className="orionis text-xs"
                  style={{ color: "var(--text-color)" }}
                >
                  Token Address: *
                </label>
                <input
                  value={pool?.tokenAddress}
                  className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                  style={{ backgroundColor: "var(--bg-color)" }}
                  type="text"
                  placeholder="Type your answer here"
                />
              </div>
              <div className="mb-0">
                <label
                  className="orionis text-xs"
                  style={{ color: "var(--text-color)" }}
                >
                  Token Supply Amount: *
                </label>
                <input
                  value={formatUnits(
                    pool && pool?.tokenTotalSupply ? pool?.tokenTotalSupply : 0,
                    tokenDecimals
                  )}
                  className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                  style={{ backgroundColor: "var(--bg-color)" }}
                  type="text"
                  placeholder="Type your answer here"
                />
              </div>
              <div className="mt-4 w-full text-left">
                {approval == ApprovalState.NOT_APPROVED ? (
                  <button
                    style={{
                      backgroundImage: `url(${heroButtonBg})`,
                      backgroundSize: "100% 100%",
                      color: "var(--text-color)",
                    }}
                    // onClick={onSubmit}
                    onClick={approveCallback}
                    className="quest bg-no-repeat px-6 py-3 max-w-full w-52"
                  >
                    Approve
                  </button>
                ) : (
                  ""
                )}
                <button
                  style={{
                    backgroundImage: `url(${heroButtonBg})`,
                    backgroundSize: "100% 100%",
                    color: "var(--text-color)",
                  }}
                  // onClick={onSubmit}
                  onClick={supplyHandle}
                  disabled={approval != ApprovalState.APPROVED}
                  className="quest bg-no-repeat px-6 py-3 max-w-full w-52"
                >
                  Supply
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default MintModal;
