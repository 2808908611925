import "./details.css";
import kidsimg from "../assets/images/kidsimg.png";
import { AiOutlineGithub } from "react-icons/ai";
import { BsInstagram } from "react-icons/bs";
import { SiDiscord } from "react-icons/si";
import { IoLogoTwitter } from "react-icons/io";
import socialBadgeBg from "../assets/images/socialBadgeBg.png";
import Path1 from "../assets/svgs/button.svg";
import detailInfoCardButton from "../assets/images/detailInfoCardButton.png";
import line from "../assets/images/detailsHeadingLine.png";
import bigLine from "../assets/images/line.png";
import detailsInfoCardBG from "../assets/images/detailsInfoCardBG.png";
import detailsTopLeftCard from "../assets/images/detailsTopLeftCard.png";
import detailsCardButton from "../assets/images/detailsCardButton.png";
import EditPoolModal from "../components/Modal/EditPoolModal";
import BidPoolModal from "../components/Modal/BidPoolModal";
import TokenMintModal from "../components/Modal/TokenMintModal";
import Loader from "../components/Loader/Loader";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { formatUnits, parseUnits } from "ethers/lib/utils";

import { AdminAddress, LaunchpadAddress } from "../config";
import { useAllowPool, useRemovePool } from "../hooks/usePool";

import useActiveWeb3React from "../hooks/useActiveWeb3React";
import { usePoolInfo } from "../hooks/contractReader/useLaunchpadInfo";
import usePoolMetadata from "../hooks/usePoolMetadata";
import { useMeijiPrice } from "../hooks/contractReader/useLPInfo";

import {
  useTokenDecimals,
  useTokenSymbol,
} from "../hooks/contractReader/useTokenInfo";
import { ipfsCidUrl } from "../utils/ipfs";

const Details = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showBidModal, setShowBidModal] = useState(false);
  const [showTokenMintModal, setShowTokenMintModal] = useState(false);
  const [phase, setPhase] = useState(0);
  const [progress, setProgress] = useState();

  const search = useLocation().search;
  const poolId = new URLSearchParams(search).get("id");

  const { account } = useActiveWeb3React();
  const allowPool = useAllowPool();
  const removePool = useRemovePool();

  const pool = usePoolInfo(poolId);
  const tokenDecimals = useTokenDecimals(pool?.tokenAddress);
  const { data: metadata } = usePoolMetadata(pool?.poolInfoUrl);

  const tokenTotalSupply = formatUnits(
    pool && pool?.tokenTotalSupply ? pool?.tokenTotalSupply : 0,
    tokenDecimals
  );
  const totalRaised = formatUnits(
    pool && pool?.totalRaised ? pool?.totalRaised : 0,
    tokenDecimals
  );

  const tokenSymbol = useTokenSymbol(pool?.tokenAddress);

  const meijiPrice = useMeijiPrice();
  console.log(meijiPrice);

  useEffect(() => {
    if (pool?.tokenTotalSupply == 0) setProgress(0);
    else setProgress((100 * pool?.totalRaised) / pool?.tokenTotalSupply);
  }, [pool?.tokenTotalSupply]);

  return (
    <>
      <div className="pt-10 details-bg min-h-screen">
        <div className="sm:w-10/12 w-11/12 mx-auto flex justify-between items-start details-top-container mb-10 md:mb-36 sm:mb-20">
          <div data-aos="fade-up" className="flex-w-half">
            <div className="flex justify-start items-center gap-6 mb-5">
              <img
                src={ipfsCidUrl(pool?.logoUrl)}
                className="rounded border-4 border-yellow-500 w-20 h-20 max-w-full"
              />
              <h1
                className="quest text-2xl md:text-4xl sm:text-3xl"
                style={{ color: "var(--text-color)" }}
              >
                {metadata?.projectName}
              </h1>
            </div>
            <p
              data-aos="fade-up"
              className="orionis text-sm md:text-base mb-8"
              style={{ color: "var(--text-color)" }}
            >
              {metadata?.projectSpecial}
            </p>
            <ul data-aos="fade-up" className="form-social-links text-3xl">
              <li
                className="inline-block text-white bg-no-repeat p-4 mr-2"
                style={{
                  backgroundImage: `url(${socialBadgeBg})`,
                  backgroundSize: "100% 100%",
                }}
              >
                <a href="#">
                  <AiOutlineGithub />
                </a>
              </li>
              <li
                className="inline-block text-white bg-no-repeat p-4 mr-2"
                style={{
                  backgroundImage: `url(${socialBadgeBg})`,
                  backgroundSize: "100% 100%",
                }}
              >
                <a href="#">
                  <SiDiscord />
                </a>
              </li>
              <li
                className="inline-block text-white bg-no-repeat p-4 mr-2"
                style={{
                  backgroundImage: `url(${socialBadgeBg})`,
                  backgroundSize: "100% 100%",
                }}
              >
                <a href="#">
                  <IoLogoTwitter />
                </a>
              </li>
              <li
                className="inline-block text-white bg-no-repeat p-4"
                style={{
                  backgroundImage: `url(${socialBadgeBg})`,
                  backgroundSize: "100% 100%",
                }}
              >
                <a href="#">
                  <BsInstagram />
                </a>
              </li>
            </ul>
          </div>

          <div data-aos="fade-up" className="flex-w-half">
            <div
              className="py-3 px-2 sm:px-3 sm:py-5 bg-no-repeat rounded-xl details-left-card"
              style={{
                backgroundImage: `url(${detailsTopLeftCard})`,
                backgroundSize: "100% 100%",
              }}
            >
              <div data-aos="fade-up" className="mb-2">
                <button
                  onClick={() => setPhase(0)}
                  style={{
                    backgroundSize: "100% 100%",
                    color: "var(--text-color)",
                    // backgroundImage:
                    //     phase == 0
                    //         ? `url(${yellow})`
                    //         : `url(${detailsCardButton})`,
                  }}
                  className={`
                                    ${phase == 0 ? "selected-pool-phase " : ""}
                                    scotia details-btn-type1 bg-no-repeat px-4 py-2 sm:px-6 sm:py-3 text-xs inline-block uppercase inline-gap`}
                >
                  1st phase
                </button>
                <button
                  onClick={() => setPhase(1)}
                  style={{
                    backgroundSize: "100% 100%",
                    color: "var(--text-color)",
                    // backgroundImage:
                    // phase == 1
                    // ? `url(${yellow})`
                    // : `url(${detailsCardButton})`,
                  }}
                  className={`
                                    ${phase == 1 ? "selected-pool-phase " : ""}
                                    scotia details-btn-type1 bg-no-repeat px-4 py-2 sm:px-6 sm:py-3 text-xs inline-block uppercase inline-gap`}
                >
                  2nd phase
                </button>
              </div>

              <div
                data-aos="fade-up"
                className="border-2 border-gray-100 border-opacity-20 rounded-lg py-3 px-1 sm:py-4 light-border bg-no-repeat bg-top"
                style={{
                  backgroundSize: "100% auto",
                  backgroundImage: "var(--bg-light-color2)",
                }}
              >
                <div className="flex justify-end mb-4">
                  <p
                    className="scotia rate-card py-3 px-5 bg-no-repeat w-fit"
                    style={{
                      backgroundImage: `url(${Path1})`,
                      backgroundSize: "100% 100%",
                      color: "var(--text-color)",
                    }}
                  >
                    {tokenSymbol == "" || tokenSymbol == undefined
                      ? "TBA"
                      : "1 MEIJI = " + pool?.tokenPrice + tokenSymbol}
                  </p>
                </div>

                <div className="px-3 sm:px-4 mt-5">
                  <div className="flex justify-between items-end gap-3 mb-5">
                    <div>
                      <h2
                        className="scotia font-thin mb-1 text-xs sm:text-sm"
                        style={{ color: "(--text-color)" }}
                      >
                        TOTAL RAISED
                      </h2>
                      <h1
                        className="quest text-xl md:text-3xl sm:text-2xl font-sans"
                        style={{ color: "var(--text-color2)" }}
                      >
                        {(parseFloat(totalRaised) * meijiPrice).toString()} BUSD
                      </h1>
                    </div>

                    <div className="border-l-2 border-gray-100 border-opacity-20 light-border pl-5 h-full">
                      <h2
                        className="scotia font-thin mb-1 text-xs sm:text-sm uppercase"
                        style={{ color: "(--text-color)" }}
                      >
                        {/* {pool?.bidStartTime && "Status"} */}
                      </h2>
                      <h1
                        className="quest text-md font-sans uppercase"
                        style={{ color: "var(--text-color2)" }}
                      >
                        {/* {pool?.bidStartTime > new Date().getTime() &&
                          timeLeft.days +
                            " days: " +
                            timeLeft.hours +
                            " hours: " +
                            timeLeft.minutes +
                            " minutes: " +
                            timeLeft.seconds +
                            " seconds"} */}
                        {pool?.bidEndTime != 0 &&
                          pool?.bidEndTime < new Date().getTime() &&
                          "closed"}
                      </h1>
                    </div>
                  </div>

                  <div>
                    <div className="flex justify-between items-center mb-1">
                      <h2
                        className="orionis text-xs font-sans font-bold"
                        style={{ color: "(--text-color)" }}
                      >
                        <p className="inline-block mr-2 opacity-70">Progress</p>
                        {progress + "%"}
                      </h2>
                      <h3
                        className="orionis text-xs font-sans font-bold"
                        style={{ color: "(--text-color)" }}
                      >
                        {tokenSymbol == "" || tokenSymbol == undefined
                          ? "TBA"
                          : totalRaised + "/" + tokenTotalSupply + tokenSymbol}
                      </h3>
                    </div>
                    {/* <div className="cyptia__card-body-progress-bar">
                      <div
                        className="battery"
                        style={{ marginRight: 100 - battery + "%" }}
                      ></div>
                    </div> */}
                    {/* <div className="battery-wrapper">
                                            <div className="cyptia__card-body-progress-bar">
                                                <div
                                                    className="battery"
                                                    style={{
                                                        // marginRight: 100 - progress + "%",
                                                        width: "100" + "%",
                                                    }}
                                                ></div>
                                            </div>
                                        </div> */}
                    {/* <ProgressBar now={60} /> */}
                    {/* <progress value={progress} max="100" style={{ width: "100%" }}> {progress}% </progress> */}
                    <div className="battery-wrapper">
                      <div className="cyptia__card-body-progress-bar">
                        <div
                          className="battery"
                          style={{
                            // marginRight: 100 - progress + "%",
                            width: progress + "%",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="flex flex-col items-end justify-end mt-3">
                      <p
                        className="orionis text-xs font-bold opacity-70 mb-4"
                        style={{ color: "(--text-color)" }}
                      >
                        Limited
                      </p>
                      <div className="flex w-full justify-between	">
                        <div>
                          <p
                            className="orionis text-xs font-bold opacity-70"
                            style={{ color: "(--text-color)" }}
                          >
                            Participants{" "}
                          </p>
                          <p
                            className="orionis text-xs font-bold"
                            style={{ color: "(--text-color)" }}
                          ></p>
                        </div>
                        <div>
                          {account == pool?.owner && !pool?.issued && (
                            <button
                              style={{
                                backgroundSize: "100% 100%",
                                color: "var(--text-color)",
                                backgroundImage: `url(${detailsCardButton})`,
                              }}
                              onClick={() => {
                                setShowEditModal(true);
                              }}
                              className="scotia details-btn-type1 bg-no-repeat px-4 py-2 sm:px-6 sm:py-3 text-xs inline-block uppercase inline-gap"
                            >
                              Edit
                            </button>
                          )}
                          {account != pool?.owner &&
                            pool?.bidEndTime >= Date.now() &&
                            pool?.issued && (
                              <button
                                style={{
                                  backgroundSize: "100% 100%",
                                  color: "var(--text-color)",
                                  backgroundImage: `url(${detailsCardButton})`,
                                }}
                                onClick={() => {
                                  setShowBidModal(true);
                                }}
                                className="scotia details-btn-type1 bg-no-repeat px-4 py-2 sm:px-6 sm:py-3 text-xs inline-block uppercase inline-gap"
                              >
                                Bid
                              </button>
                            )}
                          {account == pool?.owner &&
                            pool?.allowed &&
                            pool?.tokenAddress != "" &&
                            !pool?.issued && (
                              <button
                                style={{
                                  backgroundSize: "100% 100%",
                                  color: "var(--text-color)",
                                  backgroundImage: `url(${detailsCardButton})`,
                                }}
                                onClick={() => {
                                  setShowTokenMintModal(true);
                                }}
                                className="scotia details-btn-type1 bg-no-repeat px-4 py-2 sm:px-6 sm:py-3 text-xs inline-block uppercase inline-gap"
                              >
                                Issue Token
                              </button>
                            )}
                          {account == AdminAddress && !pool?.allowed && (
                            <button
                              style={{
                                backgroundSize: "100% 100%",
                                color: "var(--text-color)",
                                backgroundImage: `url(${detailsCardButton})`,
                              }}
                              onClick={() => allowPool(poolId)}
                              className="scotia details-btn-type1 bg-no-repeat px-4 py-2 sm:px-6 sm:py-3 text-xs inline-block uppercase inline-gap"
                            >
                              Allow Pool
                            </button>
                          )}
                          {account == AdminAddress && !pool?.removed && (
                            <button
                              style={{
                                backgroundSize: "100% 100%",
                                color: "var(--text-color)",
                                backgroundImage: `url(${detailsCardButton})`,
                              }}
                              onClick={() => removePool(poolId)}
                              className="scotia details-btn-type1 bg-no-repeat px-4 py-2 sm:px-6 sm:py-3 text-xs inline-block uppercase inline-gap"
                            >
                              Remove Pool
                            </button>
                          )}
                        </div>
                      </div>
                      {pool?.issued && (
                        <BidPoolModal
                          poolId={poolId}
                          isModelOpen={showBidModal}
                          closeModalFn={() => setShowBidModal(false)}
                          pool={pool}
                          tokenDecimals={tokenDecimals}
                        />
                      )}

                      <EditPoolModal
                        poolId={poolId}
                        isModelOpen={showEditModal}
                        closeModalFn={() => setShowEditModal(false)}
                      />
                      {pool?.tokenPrice > 0 && (
                        <TokenMintModal
                          poolId={poolId}
                          pool={pool}
                          isModelOpen={showTokenMintModal}
                          closeModalFn={() => setShowTokenMintModal(false)}
                          tokenDecimals={tokenDecimals}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {!pool && <Loader />}
              </div>
            </div>
          </div>
        </div>

        <div className="sm:w-10/12 w-11/12 mx-auto flex justify-between items-start details-top-container mb-10 md:mb-24 sm:mb-20">
          <div data-aos="fade-up" className="flex-w-half">
            <div
              data-aos="fade-up"
              className="flex justify-start items-center gap-2 sm:gap-6 mb-5"
            >
              <h1
                className="quest text-xl md:text-3xl sm:text-2xl uppercase"
                style={{ color: "var(--text-color)" }}
              >
                Pool information
              </h1>
              <img src={line} className="w-2/5" />
            </div>

            <div
              data-aos="fade-up"
              className="py-3 px-2 sm:px-3 sm:py-5 bg-no-repeat details-info-card bg-bottom"
              style={{
                backgroundImage: `url(${detailsInfoCardBG})`,
                backgroundSize: "100% 100%",
              }}
            >
              <div
                data-aos="fade-up"
                className="flex justify-between items-center w-11/12 mx-auto py-3 light-border border-b-2 border-gray-100 border-opacity-20"
              >
                <p
                  className="scotia text-xs sm:text-sm opacity-70 font-bold uppercase"
                  style={{ color: "var(--text-color)" }}
                >
                  Token Distribution
                </p>
                <p
                  className="quest text-xs sm:text-sm uppercase"
                  style={{ color: "var(--text-color)" }}
                >
                  Date utc
                </p>
              </div>
              <div
                data-aos="fade-up"
                className="flex justify-between items-center w-11/12 mx-auto py-3 light-border border-b-2 border-gray-100 border-opacity-20"
              >
                <p
                  className="scotia text-xs sm:text-sm opacity-70 font-bold uppercase"
                  style={{ color: "var(--text-color)" }}
                >
                  Min. allocation
                </p>
                <p
                  className="quest text-xs sm:text-sm uppercase"
                  style={{ color: "var(--text-color)" }}
                >
                  {pool?.minAllocation} busd
                </p>
              </div>
              <div
                data-aos="fade-up"
                className="flex justify-between items-center w-11/12 mx-auto py-3 light-border border-b-2 border-gray-100 border-opacity-20"
              >
                <p
                  className="scotia text-xs sm:text-sm opacity-70 font-bold uppercase"
                  style={{ color: "var(--text-color)" }}
                >
                  Max. allocation
                </p>
                <p
                  className="quest text-xs sm:text-sm uppercase"
                  style={{ color: "var(--text-color)" }}
                >
                  {pool?.maxAllocation === 0 && " TBA"}
                  {pool?.maxAllocation !== 0 && pool?.maxAllocation + " BUSD"}
                </p>
              </div>
              <div
                data-aos="fade-up"
                className="flex justify-between items-center w-11/12 mx-auto py-3 light-border border-b-2 border-gray-100 border-opacity-20"
              >
                <p
                  className="scotia text-xs sm:text-sm opacity-70 font-bold uppercase"
                  style={{ color: "var(--text-color)" }}
                >
                  Token price
                </p>
                <p
                  className="quest text-xs sm:text-sm uppercase"
                  style={{ color: "var(--text-color)" }}
                >
                  {tokenSymbol == "" || tokenSymbol == undefined
                    ? "TBA"
                    : "1 MEIJI = " + pool?.tokenPrice + " " + tokenSymbol}
                </p>
              </div>
              <div
                data-aos="fade-up"
                className="flex justify-between items-center w-11/12 mx-auto py-3"
              >
                <p
                  className="scotia text-xs sm:text-sm opacity-70 font-bold uppercase"
                  style={{ color: "var(--text-color)" }}
                >
                  Access type
                </p>
                <p
                  className="scotia detail-info-card-button py-2 px-4 bg-no-repeat w-fit"
                  style={{
                    backgroundImage: `url(${detailInfoCardButton})`,
                    backgroundSize: "100% 100%",
                    color: "var(--text-color)",
                  }}
                >
                  {pool?.access}
                </p>
              </div>
            </div>
          </div>

          <div data-aos="fade-up" className="flex-w-half">
            <div
              data-aos="fade-up"
              className="flex justify-start items-center gap-2 sm:gap-6 mb-5"
            >
              <h1
                className="quest text-xl md:text-3xl sm:text-2xl uppercase"
                style={{ color: "var(--text-color)" }}
              >
                Token Information
              </h1>
              <img src={line} className="w-2/5" />
            </div>

            <div
              data-aos="fade-up"
              className="py-3 px-2 sm:px-3 sm:py-5 bg-no-repeat details-info-card bg-bottom"
              style={{
                backgroundImage: `url(${detailsInfoCardBG})`,
                backgroundSize: "100% 100%",
              }}
            >
              <div
                data-aos="fade-up"
                className="flex justify-between items-center w-11/12 mx-auto py-3 light-border border-b-2 border-gray-100 border-opacity-20"
              >
                <p
                  className="scotia text-xs sm:text-sm opacity-70 font-bold uppercase"
                  style={{ color: "var(--text-color)" }}
                >
                  Name
                </p>
                <p
                  className="quest text-xs sm:text-sm uppercase"
                  style={{ color: "var(--text-color)" }}
                >
                  {pool?.tokenName == "" && "TBA"}
                  {pool?.tokenName != "" && pool?.tokenName}
                </p>
              </div>
              <div
                data-aos="fade-up"
                className="flex justify-between items-center w-11/12 mx-auto py-3 light-border border-b-2 border-gray-100 border-opacity-20"
              >
                <p
                  className="scotia text-xs sm:text-sm opacity-70 font-bold uppercase"
                  style={{ color: "var(--text-color)" }}
                >
                  Symbol
                </p>
                <p
                  className="quest text-xs sm:text-sm uppercase"
                  style={{ color: "var(--text-color)" }}
                >
                  {tokenSymbol == "" && "TBA"}
                  {tokenSymbol != "" && tokenSymbol}
                </p>
              </div>
              <div
                data-aos="fade-up"
                className="flex justify-between items-center w-11/12 mx-auto py-3 light-border border-b-2 border-gray-100 border-opacity-20"
              >
                <p
                  className="scotia text-xs sm:text-sm opacity-70 font-bold uppercase"
                  style={{ color: "var(--text-color)" }}
                >
                  Decimals
                </p>
                <p
                  className="quest text-xs sm:text-sm uppercase"
                  style={{ color: "var(--text-color)" }}
                >
                  {tokenDecimals == "" && "TBA"}
                  {tokenDecimals != "" && tokenDecimals}
                </p>
              </div>
              <div
                data-aos="fade-up"
                className="flex justify-between items-center w-11/12 mx-auto py-3 light-border border-b-2 border-gray-100 border-opacity-20"
              >
                <p
                  className="scotia text-xs sm:text-sm opacity-70 font-bold uppercase"
                  style={{ color: "var(--text-color)" }}
                >
                  Address
                </p>
                <p
                  className="quest text-xs sm:text-sm uppercase"
                  style={{ color: "var(--text-color)" }}
                >
                  {pool?.tokenAddress == undefined
                    ? "TBA"
                    : `${pool?.tokenAddress.slice(
                        0,
                        6
                      )}...${pool?.tokenAddress.slice(
                        pool?.tokenAddress.length - 4,
                        pool?.tokenAddress.length
                      )}`}
                </p>
              </div>
              <div
                data-aos="fade-up"
                className="flex justify-between items-center w-11/12 mx-auto py-3"
              >
                <p
                  className="scotia text-xs sm:text-sm opacity-70 font-bold uppercase"
                  style={{ color: "var(--text-color)" }}
                >
                  Total supply
                </p>
                <p
                  className="quest text-xs sm:text-sm uppercase"
                  style={{ color: "var(--text-color)" }}
                >
                  {tokenTotalSupply}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:w-10/12 w-11/12 mx-auto pb-32 ">
          <div
            data-aos="fade-up"
            className="grid details-about-heading items-center gap-2 sm:gap-6 mb-10"
          >
            <h1
              className="quest text-xl md:text-3xl sm:text-2xl uppercase"
              style={{ color: "var(--text-color)" }}
            >
              About the project
            </h1>
            <img src={bigLine} className="w-full" />
          </div>

          <div className="grid grid-cols-2 gap-10 md:gap-20 sm:gap-14 grid-details-about">
            <div data-aos="fade-up">
              <h2
                className="orionis text-xl sm:text-2xl mb-4"
                style={{ color: "var(--text-color)" }}
              >
                DESCRIPTION OF PROJECT
              </h2>
              <p
                data-aos="fade-up"
                className="orionis text-xs sm:text-sm opacity-70"
                style={{ color: "var(--text-color)" }}
              >
                {metadata?.projectDescription}
              </p>
            </div>

            {/* <div data-aos="fade-up">
              <h2
                className="orionis text-xl sm:text-2xl mb-4"
                style={{ color: "var(--text-color)" }}
              >
                HOW TO INCREASE PRODUCTION OF $WOD?
              </h2>
              <p
                data-aos="fade-up"
                className="orionis text-xs sm:text-sm opacity-70"
                style={{ color: "var(--text-color)" }}
              >
                You can improve the production of $WOD through:
                <ul className="list-disc list-inside py-2">
                  <li>Upgrading your equipment</li>
                  <li>Upgrading your skills</li>
                  <li>Leveling up</li>
                  <li>Upgrading your equipment</li>
                </ul>
                Higher quality equipment with a high level will allow you to get
                more $WOD.
              </p>
            </div>

            <div data-aos="fade-up">
              <h2
                className="orionis text-xl sm:text-2xl mb-4"
                style={{ color: "var(--text-color)" }}
              >
                WHAT IS $WOD?
              </h2>
              <p
                data-aos="fade-up"
                className="orionis text-xs sm:text-sm opacity-70"
                style={{ color: "var(--text-color)" }}
              >
                World of Defish token ($WOD) is a BEP-20 token with a fixed
                supply. This is World of DeFish’s governance, utility, and
                special Play-to-Earn reward token.WOD coins can be mined in any
                area of the map, regardless of the workload of the fishermen in
                the area. In order to start receiving WOD, you just need to
                select any region on the map and go fishing, you can claim your
                reward at any time.WOD coins can be mined in any area of the
                map, regardless of the workload of the fishermen in the area
              </p>
            </div>

            <div data-aos="fade-up">
              <h2
                className="orionis text-xl sm:text-2xl mb-4"
                style={{ color: "var(--text-color)" }}
              >
                WHERE CAN I USE WOD?
              </h2>
              <p
                data-aos="fade-up"
                className="orionis text-xs sm:text-sm opacity-70"
                style={{ color: "var(--text-color)" }}
              >
                $WOD is used to make purchases on the marketplace. Leveling up
                and making World of Defish token ($WOD) is a BEP-20 token with a
                fixed supply. This is World of DeFish's governance, utility, and
                special Play-to-Earn reward token.WOD coins can be mined in any
                area of the map, regardless of the workloada of the map,
                regardless of the workload of the fishermen in the area. In
                order to start receiving WOD, you just need to select any region
                on the map and go fishing, you can claim your reward at any
                time.WOD coins can be mined in any area of the map,
              </p>
            </div> */}

            {/* <div data-aos="fade-up">
              <h2
                className="orionis text-xl sm:text-2xl mb-4"
                style={{ color: "var(--text-color)" }}
              >
                HOW DO I GET $WOD?
              </h2>
              <p
                data-aos="fade-up"
                className="orionis text-xs sm:text-sm opacity-70"
                style={{ color: "var(--text-color)" }}
              >
                World of Defish token ($WOD) is a BEP-20 token with a fixed
                supply. This is World of DeFish’s governance, utility, and
                special Play-to-Earn reward token.WOD coins can be mined in any
                area of the map, regardless of the workload of the fishermen in
                the area. In order to start receiving WOD, you just need to
                select any region od to select any region od to select any
                region on the map and go fishing, you can claim your reward at
                any time.WOD coins can be mined in any area of the map,
                regardless of the workload of the fishermen in the area. In
                order to start receiving WOD,
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
