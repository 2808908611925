import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useActiveWeb3React from "./useActiveWeb3React";
import { calculateGasMargin } from "../utils";
import { getLaunchPadContract } from "../utils";
import { useGasPrice } from "../state/user/hooks";

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useAllowPool() {
  const { chainId, library, account } = useActiveWeb3React();
  const gasPrice = useGasPrice();

  return useCallback(async (poolId) => {
    const launchPadContract = getLaunchPadContract(library, account);
    const estimate = launchPadContract.estimateGas.allowPool;
    const method = launchPadContract.allowPool;

    const args = [poolId];
    const value = null;

    await estimate(...args, value ? { value } : {})
      .then((estimatedGasLimit) => {
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit),
          gasPrice,
        }).then((response) => {
          // setAttemptingTxn(false);
        });
      })
      .catch((err) => {
        // setAttemptingTxn(false);
        // we only care if the error is something _other_ than the user rejected the tx
        if (err?.code !== 4001) {
          console.log(err);
        }
      });
  });
}

export function useRemovePool() {
  const { chainId, library, account } = useActiveWeb3React();
  const gasPrice = useGasPrice();
  const navigate = useNavigate();

  return useCallback(async (poolId) => {
    const launchPadContract = getLaunchPadContract(library, account);
    const estimate = launchPadContract.estimateGas.removePool;
    const method = launchPadContract.removePool;

    const args = [poolId];
    const value = null;

    await estimate(...args, value ? { value } : {})
      .then((estimatedGasLimit) => {
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit),
          gasPrice,
        }).then((response) => {
          // setAttemptingTxn(false);
          navigate("/");
        });
      })
      .catch((err) => {
        // setAttemptingTxn(false);
        // we only care if the error is something _other_ than the user rejected the tx
        if (err?.code !== 4001) {
          console.log(err);
        }
      });
  });
}
