import { formatUnits, parseUnits } from "ethers/lib/utils";
import "./profile.css";
import profileCardBg from "../assets/images/profileCardBg.png";
import CharacterizedPools from "../sections/CharacterizedPools";
import useActiveWeb3React from "../hooks/useActiveWeb3React";
import { AdminAddress } from "../config";
import { useTotalStakingBalance } from "../hooks/contractReader/useLaunchpadInfo";
import { useFarmUserInfo } from "../hooks/contractReader/useFarmUserInfo";
import { useTokenBalance } from "../hooks/contractReader/useTokenInfo";

import {
  LaunchpadAddress,
  GovernanceTokenAddress,
  GovernanceTokenDecimals,
  FarmAddress,
} from "../config";

const Profile = ({ currentTime }) => {
  const { account } = useActiveWeb3React();

  const stakingBalanceInfo = useTotalStakingBalance(account);

  const totalStakingBalance = formatUnits(
    stakingBalanceInfo ?? 0,
    GovernanceTokenDecimals
  );

  const userInfo = useFarmUserInfo(FarmAddress, account);
  const farmStakedAmount = formatUnits(
    userInfo ? userInfo["0"] : 0,
    GovernanceTokenDecimals
  );

  const igoBalance = useTokenBalance(GovernanceTokenAddress, account);
  const balance = parseFloat(
    formatUnits(igoBalance ?? 0, GovernanceTokenDecimals)
  ).toFixed(2);

  return (
    <>
      <div className="py-10 pb-32 profile-bg min-h-screen">
        <div className="w-11/12 mx-auto">
          <div className="flex justify-between items-center gap-3 mb-14">
            <h1
              data-aos="fade-up"
              className="scotia text-2xl md:text-4xl sm:text-3xl font-bold uppercase"
              style={{ color: "var(--text-color)" }}
            >
              My profile
            </h1>
          </div>

          <div className="grid grid-cols-4 gap-10 profile-card-grid">
            <div
              data-aos="fade-up"
              className="p-10 profile-card duration-300	hover:scale-105"
              style={{
                backgroundImage: `url(${profileCardBg})`,
                backgroundSize: "100% 100%",
                color: "var(--text-color)",
              }}
            >
              <h2 className="quest text-base md:text-xl sm:text-lg uppercase mb-4">
                User details
              </h2>
              <p className="orionis text-sm text-opacity-7">
                Maecenas laoreet, mi nec condimentum auctor, ex leo imperdiet
                risus, id sodales eros diam nec odio. Ut libero velit, convallis
                nec lacinia vel, euismod a tellus. Etiam non euismod ante.
              </p>
            </div>

            <div
              data-aos="fade-up"
              className="p-10 profile-card"
              style={{
                backgroundImage: `url(${profileCardBg})`,
                backgroundSize: "100% 100%",
                color: "var(--text-color)",
              }}
            >
              <h2 className="quest text-base md:text-xl sm:text-lg uppercase mb-4">
                Staking balance
              </h2>
              <p className="quest text-2xl md:text-4xl sm:text-3xl">
                {totalStakingBalance} MEIJI
              </p>
            </div>

            <div
              data-aos="fade-up"
              className="p-10 profile-card"
              style={{
                backgroundImage: `url(${profileCardBg})`,
                backgroundSize: "100% 100%",
                color: "var(--text-color)",
              }}
            >
              <h2 className="quest text-base md:text-xl sm:text-lg uppercase mb-4">
                Liquidity balance
              </h2>
              <p className="quest text-2xl md:text-4xl sm:text-3xl">
                {farmStakedAmount} LPs
              </p>
            </div>

            <div
              data-aos="fade-up"
              className="p-10 profile-card"
              style={{
                backgroundImage: `url(${profileCardBg})`,
                backgroundSize: "100% 100%",
                color: "var(--text-color)",
              }}
            >
              <h2 className="quest text-base md:text-xl sm:text-lg uppercase mb-4">
                Total balance
              </h2>
              <p className="quest text-2xl md:text-4xl sm:text-3xl">
                {balance} MEIJI
              </p>
            </div>
          </div>

          {account == AdminAddress && (
            <div className="mt-10" data-aos="fade-top">
              <CharacterizedPools
                heading="Allow Pools"
                poolType="7"
                currentTime={currentTime}
              />
            </div>
          )}
          <div className="mt-10" data-aos="fade-top">
            <CharacterizedPools
              heading="Waiting Pools"
              poolType="6"
              currentTime={currentTime}
            />
          </div>

          <div data-aos="fade-top">
            <CharacterizedPools
              heading="Upcoming Pools"
              poolType="3"
              currentTime={currentTime}
            />
          </div>
          <div data-aos="fade-top">
            <CharacterizedPools
              heading="Featured Pools"
              poolType="4"
              currentTime={currentTime}
            />
          </div>
          <div data-aos="fade-top">
            <CharacterizedPools
              heading="COmpleted Pools"
              poolType="5"
              currentTime={currentTime}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
