import "./App.css";
// import Cards from "./components/Hero/3cards";
import Hero from "./sections/Hero";
import FormPage from "./pages/Form";
import ProfilePage from "./pages/Profile";
import StakePage from "./pages/Stake";
import DetailsPage from "./pages/Details";
import Footer from "./sections/footer/Footer";
import Navbar from "./sections/navbar/Navbar";
import ClaimsPage from "./pages/Claims";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { cardsArray, tcard } from "./utils/HeroData";
import useEagerConnect from "./hooks/useEagerConnect";
import { usePollBlockNumber } from "./state/block/hooks";

function App() {
  // usePollBlockNumber();
  useEagerConnect();
  const currentTime = Date.now();

  useEffect(() => {
    if (localStorage.getItem("themeLight") == "true") {
      document.body.classList.toggle("active");
    }
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Hero
                  currentTime={currentTime}
                  cardsArray={cardsArray}
                  tcard={tcard}
                />
                <Footer />
              </>
            }
          />
          <Route path="/apply" element={<FormPage />} />
          <Route
            path="/profile"
            element={
              <div className="bg-main">
                <Navbar />
                <ProfilePage currentTime={currentTime} />
                <Footer />
              </div>
            }
          />
          <Route
            path="/claims"
            element={
              <div className="bg-main">
                <Navbar />
                <ClaimsPage currentTime={currentTime} />
                <Footer />
              </div>
            }
          />
          <Route
            path="/staking"
            element={
              <div className="bg-main" style={{ backgroundSize: "cover" }}>
                <Navbar />
                <StakePage />
                <Footer />
              </div>
            }
          />
          <Route
            path="/pool"
            element={
              <div className="bg-main">
                <Navbar />
                <DetailsPage />
                <Footer />
              </div>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
