import useContractReader from "./ContractReader";

/** Returns total supply of tokens for project with `projectId`. */
export function usePoolUserInfo(pool, account) {
  return useContractReader({
    contract: pool,
    contractName: "stakepool",
    functionName: "userInfo",
    args: [account],
  });
}

export function usePoolPendingRewards(pool, account) {
  return useContractReader({
    contract: pool,
    contractName: "stakepool",
    functionName: "pendingReward",
    args: [account],
  });
}

export function usePoolLockDays(pool) {
  return useContractReader({
    contract: pool,
    contractName: "stakepool",
    functionName: "lockDays",
    args: [],
  });
}
