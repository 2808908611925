import { useCallback, useMemo, useState } from "react";
import useActiveWeb3React from "./useActiveWeb3React";
import { calculateGasMargin } from "../utils";
import { getLaunchPadContract } from "../utils";
import { useGasPrice } from "../state/user/hooks";
import { useTransactionAdder } from "../state/transactions/hooks";
import useContractReader from "./contractReader/ContractReader";
import { LaunchpadAddress } from "../config/index";

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useClaims(poolId) {
  const { chainId, library, account } = useActiveWeb3React();
  const gasPrice = useGasPrice();
  const addTransaction = useTransactionAdder();

  const claimableTickets = useContractReader({
    contract: LaunchpadAddress,
    contractName: "launchpad",
    functionName: "unclaimedTickets",
    args: [account, poolId],
    updateOn: [
      {
        contract: LaunchpadAddress,
        contractName: "launchpad",
        eventName: "Bid",
        topics: [poolId],
      },
      {
        contract: LaunchpadAddress,
        contractName: "launchpad",
        eventName: "Claim",
        topics: [poolId],
      },
      {
        contract: LaunchpadAddress,
        contractName: "launchpad",
        eventName: "claimTickets",
        topics: [poolId],
      },
    ],
  });

  const claimIGO = useCallback(async () => {
    const launchpadContract = getLaunchPadContract(library, account);

    const estimate = launchpadContract.estimateGas.claim;
    const method = launchpadContract.claim;

    const args = [poolId];
    const value = null;

    await estimate(...args, value ? { value } : {})
      .then((estimatedGasLimit) => {
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit),
          gasPrice,
        }).then((response) => {
          // setAttemptingTxn(false);

          addTransaction(response, {
            summary: `Claim MeijiDAO`,
          });
        });
      })
      .catch((err) => {
        // setAttemptingTxn(false);
        // we only care if the error is something _other_ than the user rejected the tx
        console.error(err?.data.message);
      });
  }, [poolId]);

  const claimTickets = useCallback(async () => {
    const launchpadContract = getLaunchPadContract(library, account);

    const estimate = launchpadContract.estimateGas.claimTickets;
    const method = launchpadContract.claimTickets;

    const args = [poolId];
    const value = null;

    await estimate(...args, value ? { value } : {})
      .then((estimatedGasLimit) => {
        console.log(estimatedGasLimit);
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit),
          gasPrice,
        }).then((response) => {
          // setAttemptingTxn(false);

          addTransaction(response, {
            summary: `Claim Ticket`,
          });
        });
      })
      .catch((err) => {
        // setAttemptingTxn(false);
        // we only care if the error is something _other_ than the user rejected the tx
        console.error(err?.data.message);
      });
  }, [poolId]);

  return [claimableTickets, claimIGO, claimTickets];
}
