import * as Sentry from "@sentry/react";
import { connectorsByName } from "./web3React";

export const clearUserStates = (dispatch, chainId) => {
  Sentry.configureScope((scope) => scope.setUser(null));
  // This localStorage key is set by @web3-react/walletconnect-connector
  if (window.localStorage.getItem("walletconnect")) {
    connectorsByName.walletconnect.close();
    connectorsByName.walletconnect.walletConnectProvider = null;
  }
  window.localStorage.removeItem("connectorIdv2");
  if (chainId) {
    // dispatch(clearAllTransactions({ chainId }));
  }
};
