import React from 'react';
import heroButtonBg from "../../assets/default/Frame-4.png";
import './pagination.css'

const Pagination = () => {
  return (
    <>
      <div data-aos="fade-up" className="pagination sm:w-10/12 w-11/12 mx-auto flex">
        <div className="leftBtn">
          <button style={{ backgroundImage: `url(${heroButtonBg})`, backgroundSize: "100% 100%", color: "var(--text-color)" }} className="scotia text-xs unavailableBtn p-3 bg-cover bg-no-repeat px-3 py-3">
            <span style={{fonSize: '1em', color: "var(--text-color)"}}>{'<< '}</span> Previous
          </button>
        </div>
        <div className="numbers flex justify-between">
          <button className='paginationBtn' style={{ color: "var(--text-color)" }}>1</button>
          <button className='paginationBtn' style={{ color: "var(--text-color)" }}>2</button>
          <button className='paginationBtn' style={{ color: "var(--text-color)" }}>3</button>
          <button className='paginationBtn' style={{ color: "var(--text-color)" }}>4</button>
          <button className='paginationBtn' style={{ color: "var(--text-color)" }}>5</button>
          <button className='paginationBtn' style={{ color: "var(--text-color)" }}>6</button>
        </div>
        <div className="rightBtn">
          <button style={{ backgroundImage: `url(${heroButtonBg})`, backgroundSize: "100% 100%", color: "var(--text-color)" }} className="scotia text-xs unavailableBtn p-3 bg-cover bg-no-repeat px-3 py-3">
            Next <span style={{ fonSize: '1em', color: "var(--text-color)" }}>{' >>'}</span>
          </button>
        </div>
      </div>
    </>
  )
};


export default Pagination;
