import "./CarouselItem.css";

const CarouselItem = ({ img }) => {
  return (
    <div className="hero-carousel-item w-full">
      <img
        className="w-full h-full object-fill m-auto"
        src={img}
        alt="Banner"
      />
    </div>
  );
};

export default CarouselItem;
