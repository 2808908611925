import BigNumber from "bignumber.js/bignumber";
import { BIG_TEN } from "../utils/bigNumber";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

export const BSC_BLOCK_TIME = 3;

export const BASE_BSC_SCAN_URLS = {
  56: "https://bscscan.com",
  97: "https://testnet.bscscan.com",
};

// CAKE_PER_BLOCK details
// 40 Zoinks is minted per block
// 20 Zoinks per block is sent to Burn pool (A farm just for burning cake)
// 10 Zoinks per block goes to Zoinks syrup pool
// 9 Zoinks per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// Zoinks/Block in src/views/Home/components/CakeDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const CAKE_PER_BLOCK = 0.0001;
export const BLOCKS_PER_YEAR = (60 / BSC_BLOCK_TIME) * 60 * 24 * 365; // 10512000
export const CAKE_PER_YEAR = CAKE_PER_BLOCK * BLOCKS_PER_YEAR;
export const BASE_URL = "https://pancakeswap.finance";
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`;
export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS["56"];
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18);
export const DEFAULT_GAS_LIMIT = 500000;
export const AUCTION_BIDDERS_TO_FETCH = 500;
export const RECLAIM_AUCTIONS_TO_FETCH = 500;
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500;
export const IPFS_GATEWAY = "https://ipfs.io/ipfs";
// In reality its 10000 because of fast refresh, a bit less here to cover for possible long request times
export const PANCAKE_BUNNIES_UPDATE_FREQUENCY = 8000;

export const ChainId = {
  MAINNET: 56,
  TESTNET: 97,
};

export const LaunchpadAddress = "0xdc8b7A3690ee56f7354B3B7939906e77b179ab98";
export const GovernanceTokenAddress =
  "0x03d7fdA755a1ED534F808F81d5524032B28167ef";

export const GovernanceTokenDecimals = 18;
export const LPTokenAddress = "0x7258b0aedd4554f1bf1b077397463284892b8595";
export const FarmAddress = "0x1c1F94413f9103F28C932CD7f5A5A56498CFc901";
export const AdminAddress =
  process.env.ADMIN_ADDRESS || "0x96E7d4EEC9B54123cb7b07AD7C1840005Af0fD20";
