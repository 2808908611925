import React from "react";
import frame from "../../assets/svgs/Tcard.svg";
import Bitmap from "../../assets/images/Bitmap.png";
import BitmapLight from "../../assets/images/BitmapLight.png";
import cardBG from "../../assets/images/heroCardBG.png";
import tCardButton from "../../assets/images/tCardButton.png";
import Group from "../../assets/images/Group1.png";
import "./tcard.css";

const Tcard = ({ id, title, subtitle, desc }) => {
  return (
    <>
      <div
        className="tcard bg-no-repeat shadow-2xl relative text-center py-10 px-12 pb-20"
        style={{
          backgroundImage: `url(${cardBG})`,
          backgroundSize: "100% 100%",
        }}
      >
        <h2 className="scotia font-bold text-lg">
          {title} <span>{subtitle}</span>{" "}
        </h2>
        <img
          src={
            document.body.classList.contains("active") ? BitmapLight : Bitmap
          }
          alt="line"
          className="mx-auto py-2"
        />
        <p className="orionis text-xs">{desc}</p>
        <div
          className="t-card-button bg-no-repeat py-1 px-7 absolute bottom-4 left-1/2 -translate-x-1/2 "
          style={{
            backgroundImage: `url(${tCardButton})`,
            backgroundSize: "100% 100%",
          }}
        >
          <button
            className="scotia text-xs"
            style={{ color: "var(--text-color)" }}
          >
            Learn More&nbsp;&nbsp;&gt;&gt;
          </button>
        </div>
      </div>
    </>
  );
};

export default Tcard;
