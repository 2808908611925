import React, { useEffect, useState } from "react";
import { formatUnits } from "ethers/lib/utils";
import stackingLeftCardButton from "../../../assets/images/stackingLeftCardButton.png";
import {
  FarmAddress,
  LPTokenAddress,
  GovernanceTokenDecimals,
} from "../../../config";
import {
  useApproveCallback,
  ApprovalState,
} from "../../../hooks/useApproveCallback";
import {
  useFarmUserInfo,
  useFarmPendingRewards,
  useRewardPerMinute,
  useTotalParticipants,
  useTotalRewards,
  useCurrentBlock,
  useLockDuration,
} from "../../../hooks/contractReader/useFarmUserInfo";
import useActiveWeb3React from "../../../hooks/useActiveWeb3React";
import FarmStakeModal from "../../../components/Modal/FarmStakeModal";
import { useTokenBalance } from "../../../hooks/contractReader/useTokenInfo";
import { useStakeFarm } from "../../../hooks/useStakeFarm";
import Loader from "../../../components/Loader/Loader";

const FarmDetail = () => {
  const { account } = useActiveWeb3React();

  const [showStakeModal, setShowStakeModal] = useState(false);
  const [type, setType] = useState(0); // 0: Stake, 1: withdraw, 2: claim
  const [approval, approveCallback] = useApproveCallback(
    1,
    LPTokenAddress,
    FarmAddress
  );

  const userInfo = useFarmUserInfo(FarmAddress, account);
  const { rewPerBlock, blocksPerHour } = useRewardPerMinute(FarmAddress);
  const lockDuration = useLockDuration(FarmAddress);
  const stake = useStakeFarm();

  const userLastStakeTime = parseInt(userInfo ? userInfo["4"] : 0);

  const unlockDate = userLastStakeTime + 60 * 60 * lockDuration;

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    if (unlockDate * 1000 > new Date().getTime()) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
    } else {
      setTimeLeft(undefined);
    }
  });

  function calculateTimeLeft() {
    const difference = unlockDate * 1000 - new Date().getTime();

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  const lpTokenBalance = useTokenBalance(LPTokenAddress, account);
  const balance = formatUnits(lpTokenBalance ?? 0, GovernanceTokenDecimals);

  const pendingRewards = useFarmPendingRewards(FarmAddress, account);
  const totalParticipants = useTotalParticipants(FarmAddress);
  const totalRewards = useTotalRewards(FarmAddress);

  const stakedAmount = formatUnits(
    userInfo ? userInfo["0"] : 0,
    GovernanceTokenDecimals
  );
  const rewardAmount = formatUnits(
    pendingRewards ?? 0,
    GovernanceTokenDecimals
  );
  const rewardsPerMinute =
    (formatUnits(rewPerBlock ? rewPerBlock : 0, GovernanceTokenDecimals) *
      blocksPerHour) /
    60;
  const totalReward = formatUnits(totalRewards ?? 0, GovernanceTokenDecimals);

  const stakeHandle = () => {
    setType(0);
    setShowStakeModal(true);
  };

  const withdrawHandle = () => {
    setType(1);
    setShowStakeModal(true);
  };

  const claimHandle = () => {
    setType(2);
    // setShowStakeModal(true);
    stake(2, 0);
  };

  return (
    <div
      data-aos="fade-up"
      className=" p-0   bg-bottom"
      style={{
        backgroundImage: "none",
      }}
    >
      <div
        data-aos="fade-up"
        className="flex justify-between items-center w-11/12 mx-auto mt-5"
      >
        <p
          className="scotia text-sm sm:text-sm font-bold uppercase"
          style={{ color: "var(--text-color)" }}
        >
          Pool Details
        </p>
        <p
          className="quest text-sm sm:text-sm uppercase"
          style={{ color: "var(--text-color)" }}
        >
          {rewardAmount} MEIJI Earned
        </p>
      </div>
      {rewardAmount > 0 && (
        <div
          data-aos="fade-up"
          className="flex justify-end items-center w-11/12 mx-auto py-3 light-border border-b-2 border-gray-100 border-opacity-20"
        >
          <button
            data-aos="fade-up"
            onClick={claimHandle}
            style={{ color: "var(--text-color)" }}
            className=" selected-duration scotia stake-btn-type2 ml-0 px-3 py-1 sm:px-4 sm:py-2 text-xs
              border-2 rounded-md border-gray-100 border-opacity-20 inline-block uppercase inline-gap"
          >
            Claim
          </button>
        </div>
      )}
      <div
        data-aos="fade-up"
        className="flex justify-between items-center w-11/12 mx-auto py-3 light-border border-b-2 border-gray-100 border-opacity-20"
      >
        <p
          className="scotia text-xs sm:text-sm opacity-70 font-bold uppercase"
          style={{ color: "var(--text-color)" }}
        >
          APY
        </p>
        <p
          className="quest text-xs sm:text-sm uppercase"
          style={{ color: "var(--text-color)" }}
        >
          103 %
        </p>
      </div>
      <div
        data-aos="fade-up"
        className="flex justify-between items-center w-11/12 mx-auto py-3 light-border border-b-2 border-gray-100 border-opacity-20"
      >
        <p
          className="scotia text-xs sm:text-sm opacity-70 font-bold uppercase"
          style={{ color: "var(--text-color)" }}
        >
          REWARD PER MINUTE
        </p>
        <p
          className="quest text-xs sm:text-sm uppercase"
          style={{ color: "var(--text-color)" }}
        >
          {rewardsPerMinute ?? 0} MEIJI
        </p>
      </div>
      <div
        data-aos="fade-up"
        className="flex justify-between items-center w-11/12 mx-auto py-3 light-border border-b-2 border-gray-100 border-opacity-20"
      >
        <p
          className="scotia text-xs sm:text-sm opacity-70 font-bold uppercase"
          style={{ color: "var(--text-color)" }}
        >
          UNLOCKS IN
        </p>
        <p
          className="quest text-xs sm:text-sm uppercase"
          style={{ color: "var(--text-color)" }}
        >
          {timeLeft &&
            timeLeft.days +
              " days " +
              timeLeft.hours +
              " hours " +
              timeLeft.minutes +
              " minutes " +
              timeLeft.seconds +
              " seconds"}
        </p>
      </div>
      <div
        data-aos="fade-up"
        className="flex justify-between items-center w-11/12 mx-auto py-3 light-border border-b-2 border-gray-100 border-opacity-20"
      >
        <p
          className="scotia text-xs sm:text-sm opacity-70 font-bold uppercase"
          style={{ color: "var(--text-color)" }}
        >
          YOUR STAKE
        </p>
        <p
          className="quest text-xs sm:text-sm uppercase"
          style={{ color: "var(--text-color)" }}
        >
          {stakedAmount} LP
        </p>
      </div>
      <div
        data-aos="fade-up"
        className="flex justify-between items-center w-11/12 mx-auto py-3 light-border border-b-2 border-gray-100 border-opacity-20"
      >
        <p
          className="scotia text-xs sm:text-sm opacity-70 font-bold uppercase"
          style={{ color: "var(--text-color)" }}
        >
          POOL SHARE
        </p>
        <p
          className="quest text-xs sm:text-sm uppercase"
          style={{ color: "var(--text-color)" }}
        >
          -
        </p>
      </div>{" "}
      <div
        data-aos="fade-up"
        className="flex justify-between items-center w-11/12 mx-auto py-3 light-border border-b-2 border-gray-100 border-opacity-20"
      >
        <p
          className="scotia text-xs sm:text-sm opacity-70 font-bold uppercase"
          style={{ color: "var(--text-color)" }}
        >
          PARTICIPANTS
        </p>
        <p
          className="quest text-xs sm:text-sm uppercase"
          style={{ color: "var(--text-color)" }}
        >
          {1 * totalParticipants}
        </p>
      </div>
      <div
        data-aos="fade-up"
        className="flex justify-between items-center w-11/12 mx-auto py-3 "
      >
        <p
          className="scotia text-xs sm:text-sm opacity-70 font-bold uppercase"
          style={{ color: "var(--text-color)" }}
        >
          DISTRIBUTED TOKENS
        </p>
        <p
          className="quest text-xs sm:text-sm uppercase"
          style={{ color: "var(--text-color)" }}
        >
          10,000 / {totalReward}
        </p>
      </div>
      <button
        data-aos="fade-up"
        style={{
          backgroundImage: `url(${stackingLeftCardButton})`,
          backgroundSize: "100% 100%",
          color: "var(--text-color)",
        }}
        className=" mt-3 scotia stake-btn-type3 bg-no-repeat w-full py-3 sm:py-5 text-lg uppercase"
        onClick={() =>
          window.open(
            "https://zoinksdev.netlify.app/add/0xD0F7d3611d2faDc1838FBAc6c2Dbe19EC8cCE359/0x521b9994d9F26c5106ef48079e2F28C0a7cCe652",
            "_blank"
          )
        }
      >
        View Pool
      </button>
      <div
        data-aos="fade-up"
        className="flex justify-between items-center w-11/12 mx-auto py-3 "
      >
        <p
          className="scotia text-xs sm:text-sm opacity-70 font-bold uppercase"
          style={{ color: "var(--text-color)" }}
        >
          Balance:
        </p>
        <p
          className="quest text-xs sm:text-sm uppercase"
          style={{ color: "var(--text-color)" }}
        >
          {balance} LP
        </p>
      </div>
      <div style={{ display: "flex" }}>
        <button
          data-aos="fade-up"
          style={{
            backgroundImage: `url(${stackingLeftCardButton})`,
            backgroundSize: "100% 100%",
            color: "var(--text-color)",
            margin: "5px",
          }}
          onClick={
            approval != ApprovalState.APPROVED ? approveCallback : stakeHandle
          }
          className="scotia stake-btn-type3 bg-no-repeat w-full py-3 sm:py-5 text-lg uppercase"
        >
          {approval != ApprovalState.APPROVED ? "Approve" : "Stake"}
        </button>
        {stakedAmount > 0 && (
          <button
            data-aos="fade-up"
            style={{
              backgroundImage: `url(${stackingLeftCardButton})`,
              backgroundSize: "100% 100%",
              color: "var(--text-color)",
              margin: "5px",
            }}
            disabled={unlockDate * 1000 > new Date().getTime()}
            onClick={withdrawHandle}
            className="scotia stake-btn-type3 bg-no-repeat w-full py-3 sm:py-5 text-lg uppercase"
          >
            Withdraw
          </button>
        )}
      </div>
      <FarmStakeModal
        type={type}
        isModelOpen={showStakeModal}
        closeModalFn={() => setShowStakeModal(false)}
      />
      {!lockDuration && <Loader />}
    </div>
  );
};

export default FarmDetail;
