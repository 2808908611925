import React, { useMemo } from "react";
import { formatDate } from "../../utils/formatDate";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import binanceIcon from "../../assets/icons/Network_1.png";
import { useClaims } from "../../hooks/useClaims";
import usePoolMetadata from "../../hooks/usePoolMetadata";
import { ipfsCidUrl } from "../../utils/ipfs";
import Button from "./Button";
import "./claimCard.css";

export default function ClaimCard({ pool }) {
  const { account } = useActiveWeb3React();
  const [claimableTickets, claimIGO, claimTickets] = useClaims(pool.id);

  const { data: metadata } = usePoolMetadata(pool?.poolInfoUrl);
  const isClaimable = useMemo(() => {
    if (account) {
      if (pool.owner.toLocaleLowerCase() == account.toLocaleLowerCase()) {
        console.log(pool.totalRaised - pool.claimed);
        if (pool.totalRaised - pool.claimed > 0) {
          return true;
        }
      } else {
        if (claimableTickets > 0) {
          return true;
        }
      }
    }

    return false;
  }, [pool, claimableTickets]);

  const claimHandle = () => {
    if (account) {
      if (pool.owner.toLocaleLowerCase() == account.toLocaleLowerCase()) {
        claimIGO();
      } else {
        claimTickets();
      }
    }
  };

  return (
    <>
      <div data-aos="fade-up" className="flex min-w-full">
        <div
          className="claimCard rounded-lg bg-no-repeat"
          style={{
            // backgroundImage: `url(${bgImg})`,
            backgroundSize: "100% 100%",
            width: "100%",
          }}
        >
          <div className="cardContent rounded-lg p-5 md:items-center flex border-2 border-gray-100 border-opacity-20 light-border">
            <div className="">
              <div className="h-fit w-16 mr-5 avatar border-2 border-yellow-300 rounded-sm overflow-hidden ">
                <img src={ipfsCidUrl(pool?.logoUrl)} alt="" />
              </div>
            </div>
            <div className="rightSide flex justify-between w-full">
              <div
                className="cardRight flex items-center justify-evenly"
                style={{
                  width: "100%",
                  // justifyContent: "space-evenly"
                }}
              >
                <div className="cardTitle items-center">
                  <h2
                    className="scotia smHeadingText"
                    style={{ color: "var(--text-color)" }}
                  >
                    {metadata?.projectName}
                  </h2>
                </div>
                <div className="cardCenterItems flex">
                  <div className="cardCenterItemItem border-l-2 border-gray-100 border-opacity-20 light-border pl-9 cardNetwork text-sm">
                    <div
                      className="subtitle scotia"
                      style={{ color: "var(--text-color)" }}
                    >
                      Owner
                    </div>
                    <div className="networkName flex items-center">
                      <span
                        className="orionis text-white"
                        style={{ color: "var(--text-color)" }}
                      >
                        {pool.owner == account
                          ? "You"
                          : pool.owner.slice(0, 6) +
                            "..." +
                            pool.owner.slice(
                              pool.owner.length - 4,
                              pool.owner.length
                            )}
                      </span>
                    </div>
                  </div>
                  <div className="cardCenterItemItem border-l-2 border-gray-100 border-opacity-20 light-border pl-9 cardNetwork text-sm">
                    <div
                      className="subtitle scotia"
                      style={{ color: "var(--text-color)" }}
                    >
                      Network
                    </div>
                    <div className="networkName flex items-center">
                      <img src={binanceIcon} className="md:h-6" alt="" />
                      <span
                        className="orionis text-white"
                        style={{ color: "var(--text-color)" }}
                      >
                        Binance
                      </span>
                    </div>
                  </div>
                  <div className="cardCenterItemItem allocation border-l-2 border-gray-100 border-opacity-20 light-border pl-9 text-sm">
                    <div
                      className="subtitle scotia"
                      style={{ color: "var(--text-color)" }}
                    >
                      Your Allocation
                    </div>
                    <div>
                      <span
                        className="orionis text-white"
                        style={{ color: "var(--text-color)" }}
                      >
                        {" "}
                        Public
                      </span>
                    </div>
                  </div>
                  {/* <div className="border h-full w-1 border-l-2 border-gray-100 border-opacity-50"></div> */}
                  <div className="cardCenterItemItem border-l-2 border-gray-100 border-opacity-20 light-border pl-9 text-sm">
                    <div
                      className="subtitle scotia"
                      style={{ color: "var(--text-color)" }}
                    >
                      Claimstart Date
                    </div>
                    <div>
                      <span
                        className="orionis text-white"
                        style={{ color: "var(--text-color)" }}
                      >
                        {formatDate(pool?.bidEndTime, "MM-DD-YY h:mma")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cardBtn">
                <Button isAvailable={isClaimable} claimHandle={claimHandle} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
