export const cardsArray = [
  {
    text: "Apply as a project",
    href: "/apply",
  },
  {
    text: "Buy on Pancakeswap",
  },
  {
    text: "Governance Voting",
  },
  {
    text: "ROI Data",
  },
];

export const tcard = [
  {
    title: "WHAT IS A ",
    title2: "LAUNCHPAD?",
    desc: "Crypto launchpads are platforms that fulfill the function of hosting various crypto and blockchain-based projects to raise sufficient capital while also giving early access benefits to investors like token sales with reduced prices. Investing in a project in its early stages guarantees investors a cheaper cost of entry into the project before the public launch.",
  },
  {
    title: "TIER ",
    title2: "SYSTEM",
    desc: "MeijiDAO has a unique tier system consisting of 9 different tier levels allowing both small and large token holders access to the most sought after presales. The higher tier level you hold the more allocation you will receive when it comes to the presales hosted on our platform. ",
  },
  {
    title: "HOW TO GET ",
    title2: "STARTED?",
    desc: "The easiest way to get started is joining the Meiji community chat and reading up on all articles. Holding $MEIJI gives you access to presales, DAO voting and unique whitelist spots for incubated projects. ",
  },
];
