import { Contract } from "@ethersproject/contracts";
import { useCallback, useContext, useState } from "react";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import useActiveWeb3React from "../useActiveWeb3React";
import { getContract, getProviderOrSigner } from "../../utils";
import { useBlock } from "../../state/block/hooks";
export default function useContractReader({
  contract,
  contractName,
  functionName,
  args,
  updateOn,
  formatter,
  callback,
  valueDidChange,
}) {
  const [value, setValue] = useState();

  const { library, account } = useActiveWeb3React();

  const signerOrProvider = getProviderOrSigner(library, account);

  const _formatter = useCallback(
    (val) => (formatter ? formatter(val) : val),
    [formatter]
  );
  const _callback = useCallback(
    (val) => (callback ? callback(val) : val),
    [callback]
  );
  const _valueDidChange = useCallback(
    (a, b) => (valueDidChange ? valueDidChange(a, b) : a !== b),
    [valueDidChange]
  );

  const { currentBlock } = useBlock();

  useDeepCompareEffectNoCheck(() => {
    async function getValue() {
      const readContract = loadContract(
        contract,
        signerOrProvider,
        contractName
      );
      if (!readContract || !functionName || args === null) return;

      try {
        console.log("📚 Read >", functionName);

        const result = await readContract[functionName](...(args ?? []));
        const newValue = _formatter(result);

        if (_valueDidChange(value, newValue)) {
          console.log(
            "📗 New >",
            functionName,
            { args },
            { newValue },
            { contract: readContract.address }
          );
          setValue(newValue);
          _callback(newValue);
        }
      } catch (err) {
        // console.log(
        //   "📕 Read error >",
        //   functionName,
        //   { args },
        //   { err },
        //   { contract: readContract.address },
        //   contracts
        // );
        setValue(_formatter(undefined));
        _callback(_formatter(undefined));
      }
    }

    getValue();

    const listener = (x) => getValue();

    let subscriptions = [];

    if (updateOn?.length) {
      try {
        // Subscribe listener to updateOn events
        updateOn.forEach((u) => {
          const _contract = loadContract(
            u.contract,
            signerOrProvider,
            u.contractName
          );

          if (!u.eventName || !_contract) return;

          const filter = _contract.filters[u.eventName](...(u.topics ?? []));
          _contract?.on(filter, listener);
          subscriptions.push({
            contract: _contract,
            filter,
          });
        });
      } catch (error) {
        console.log("Read contract >", {
          functionName,
          error,
        });
      }
    }

    return () =>
      subscriptions.forEach((s) => s.contract.off(s.filter, listener));
  }, [
    contract,
    functionName,
    updateOn,
    args,
    _formatter,
    _callback,
    _valueDidChange,
    currentBlock,
  ]);

  return value;
}

const loadContract = (contract, signerOrProvider, contractName = "") => {
  if (contractName == "") {
    const cont = require(`../../config/abi/${contract}.json`);
    return new Contract(cont.address, cont.abi, signerOrProvider);
  } else {
    const abi = require(`../../config/abi/${contractName}.json`);
    return new Contract(contract, abi, signerOrProvider);
  }
};
