import useContractReader from "./ContractReader";

/** Returns total supply of tokens for project with `projectId`. */
export function useTokenBalance(token, owner) {
  return useContractReader({
    contract: token,
    contractName: "erc20",
    functionName: "balanceOf",
    args: [owner],
    updateOn: [
      {
        contract: token,
        contractName: "erc20",
        eventName: "Transfer",
        topics: [],
      },
    ],
  });
}

export function useTokenAllowance(token, owner, spender) {
  return useContractReader({
    contract: token,
    contractName: "erc20",
    functionName: "allowance",
    args: [owner, spender],
    updateOn: [
      {
        contract: token,
        contractName: "erc20",
        eventName: "Approval",
        topics: [owner, spender],
      },
    ],
  });
}

export function useTokenDecimals(token) {
  return useContractReader({
    contract: token,
    contractName: "erc20",
    functionName: "decimals",
    args: [],
  });
}

export function useTokenSymbol(token) {
  return useContractReader({
    contract: token,
    contractName: "erc20",
    functionName: "symbol",
    args: [],
  });
}
