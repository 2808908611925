const pools = [
  {
    id: 0,
    stakingToken: "",
    earningToken: "",
    lockDays: 7,
    contractAddress: "0x1D50fA80A4d3BA952fC24cDA9bfA85D3c0dEf035",
    harvest: true,
    tokenPerBlock: "0.0001",
    sortOrder: 1,
    isFinished: false,
  },
  {
    id: 1,
    stakingToken: "",
    earningToken: "",
    lockDays: 14,
    contractAddress: "0x9C2c5F3a11d137231d553a3654e265de1737959d",
    harvest: true,
    tokenPerBlock: "0.0001",
    sortOrder: 1,
    isFinished: false,
  },
  {
    id: 2,
    stakingToken: "",
    earningToken: "",
    lockDays: 30,
    contractAddress: "0x9d5999e8E645AAfca919E6BB1F5E48a2614a9C1A",
    harvest: true,
    tokenPerBlock: "0.0001",
    sortOrder: 1,
    isFinished: false,
  },
  {
    id: 3,
    stakingToken: "",
    earningToken: "",
    lockDays: 60,
    contractAddress: "0xE993668f868AE7375EE642839ee99f2330bc214C",
    harvest: true,
    tokenPerBlock: "0.0001",
    sortOrder: 1,
    isFinished: false,
  },
  {
    id: 4,
    stakingToken: "",
    earningToken: "",
    lockDays: 90,
    contractAddress: "0xfC469831DD3866e9f2b68F04ADB5eb17774A62E2",
    harvest: true,
    tokenPerBlock: "0.0001",
    sortOrder: 1,
    isFinished: false,
  },
];

export default pools;
