import Axios from "axios";

const pinata_api_key = process.env.REACT_APP_PINATA_PINNER_KEY || "";
const pinata_secret_api_key = process.env.REACT_APP_PINATA_PINNER_SECRET || "";

export const IPFS_TAGS = {
  METADATA:
    process.env.NODE_ENV === "production"
      ? "igo_metadata"
      : "igo_test_metadata",
  LOGO: process.env.NODE_ENV === "production" ? "igo_logo" : "igo_test_logo",
};

const formatQueryMetadata = (metadata) => {
  const query = metadata
    ? Object.keys(metadata).reduce(
        (acc, key) => (acc += `"${key}": ${JSON.stringify(metadata[key])}`),
        ""
      )
    : undefined;

  return `?metadata[keyvalues]={${query}}`;
};

const formatQueryFilters = (filters) =>
  filters
    ? Object.keys(filters).reduce(
        (acc, key, i) => (acc += `${i > 0 ? "&" : ""}${key}=${filters[key]}`),
        "?"
      )
    : "";

// keyvalues will be upserted to existing metadata. A null value will remove an existing keyvalue
export const editMetadataForCid = (cid, options) =>
  cid
    ? Axios.put(
        "https://api.pinata.cloud/pinning/hashMetadata",
        {
          ipfsPinHash: cid,
          name: options?.name,
          keyvalues: options?.keyvalues,
        },
        {
          headers: {
            pinata_api_key,
            pinata_secret_api_key,
          },
        }
      )
        .then((res) => true)
        .catch((err) => {
          console.error("Failed to set metadata ", { cid, options }, err);
          return false;
        })
    : undefined;

export const metadataNameForHandle = (handle) => `igo-@${handle}-metadata`;

export const logoNameForHandle = (handle) => `igo-@${handle}-logo`;

export const ipfsCidUrl = (hash) => "https://gateway.pinata.cloud/ipfs/" + hash;

export const cidFromUrl = (url) => url?.split("/").pop();

export const pinFileToIpfs = (file, options) => {
  options?.beforeUpload && options.beforeUpload();

  let data = new FormData();

  data.append("file", file);

  if (options?.metadata) {
    data.append(
      "pinataMetadata",
      JSON.stringify({
        keyvalues: options.metadata,
      })
    );
  }

  return Axios.post("https://api.pinata.cloud/pinning/pinFileToIPFS", data, {
    maxContentLength: Infinity, //this is needed to prevent axios from erroring out with large files
    headers: {
      "Content-Type": `multipart/form-data;`,
      pinata_api_key,
      pinata_secret_api_key,
    },
  })
    .then((res) => {
      const cid = res.data.IpfsHash;
      options?.onSuccess && options.onSuccess(cid);
      return {
        success: true,
        cid,
        url: ipfsCidUrl(cid),
        res,
      };
    })
    .catch((err) => {
      options?.onError && options.onError(err);
      return {
        success: false,
        err,
      };
    });
};

export const pinJSONToIpfs = (json, options) =>
  Axios.post(
    "https://api.pinata.cloud/pinning/pinJSONToIPFS",
    {
      pinataContent: json,
      ...(options?.metadata || options?.name
        ? {
            pinataMetadata: {
              ...(options?.name ? { name: options.name } : {}),
              ...(options?.metadata ? { keyvalues: options.metadata } : {}),
            },
          }
        : {}),
    },
    {
      headers: {
        pinata_api_key,
        pinata_secret_api_key,
      },
    }
  )
    .then((res) => {
      const cid = res.data.IpfsHash;
      options?.onSuccess && options.onSuccess(cid);
      return {
        success: true,
        cid,
        url: ipfsCidUrl(cid),
        res,
      };
    })
    .catch((err) => {
      options?.onError && options.onError(err);
      return {
        success: false,
        err,
      };
    });

export const unpinIpfsFileByCid = (cid) =>
  cid
    ? Axios.delete(`https://api.pinata.cloud/pinning/unpin/${cid}`, {
        headers: {
          pinata_api_key,
          pinata_secret_api_key,
        },
      })
        .then(() => true)
        .catch((err) => {
          console.error("Failed to unpin file ", cid, err);
          return false;
        })
    : Promise.resolve(false);

export const uploadProjectMetadata = (metadata) =>
  pinJSONToIpfs(
    {
      ...metadata,
      version: 3,
    },
    {
      name: "igo-project-metadata.json",
      metadata: {
        tag: IPFS_TAGS.METADATA,
      },
    }
  ).then((res) => {
    if (!res.success) {
      console.log({
        key: new Date().valueOf().toString(),
        message: "Failed to upload project metadata",
        description: JSON.stringify(res.err),
        duration: 0,
      });
    }
    return res;
  });
