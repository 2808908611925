import React from "react";
import usePoolMetadata from "../../hooks/usePoolMetadata";
import { ipfsCidUrl } from "../../utils/ipfs";
import "./ucard.css";
import frame from "../../assets/images/frame.png";
import Path1 from "../../assets/svgs/button.svg";
import Group3 from "../../assets/images/group3.png";
import { useTokenSymbol } from "../../hooks/contractReader/useTokenInfo";

const UCard = ({ poolType, pool }) => {
  let tokenPrice = pool?.tokenPrice;

  let tokenDecimals = pool?.tokenDecimals;

  while (tokenDecimals > 0) {
    tokenPrice /= 10;
    tokenDecimals--;
  }

  const { data: metadata } = usePoolMetadata(pool?.poolInfoUrl);

  const tokenSymbol = useTokenSymbol(pool?.tokenAddress);

  return (
    <div data-aos="fade-up" className="cardFeatures">
      <div
        className="cardfeature one relative mt-5 py-2 px-1 pb-3 bg-no-repeat"
        style={{
          backgroundImage: `url(${frame})`,
          backgroundSize: "100% 100%",
        }}
      >
        <div
          style={{
            // backgroundColor: "var(--bg-color)",
            height: "100%",
            width: "100%",
          }}
        >
          <div className="firstcard">
            <div className="cardimage">
              {/* <img src="../../assets/images/kidsimg.png" alt="" srcset="" /> */}
              <div
                className="cardimg rounded"
                style={{
                  background: `url(${ipfsCidUrl(pool.logoUrl)})`,
                  backgroundSize: "100% 100%",
                  height: "40%",
                  width: "70%",
                  border: "3px solid gold",
                  color: "var(--text-color)",
                }}
              ></div>
              <div
                className="bg-no-repeat  relative  p-2 card-image-phase"
                style={{
                  background: `url(${Group3})`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "20%",
                  width: "70%",
                  backgroundSize: "100% 100%",
                  color: "var(--text-color)",
                }}
              >
                <p
                  className="scotia uppercase text-s "
                  style={{ fontSize: "0.6rem", color: "var(--text-color)" }}
                >
                  1st phase
                </p>
              </div>
            </div>
            <div className="cardtext">
              <div className="upper">
                <h1
                  className="quest uppercase text-xl "
                  style={{ color: "var(--text-color)" }}
                >
                  {metadata?.name}
                </h1>
                <div
                  className="bg-no-repeat  relative"
                  style={{
                    background: `url(${Path1})`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "2rem",
                    width: "9rem",
                    color: "var(--text-color)",
                    backgroundSize: "100% 100%",
                  }}
                >
                  <p
                    className="scotia uppercase text-sm"
                    style={{
                      fontSize: "0.7rem",
                      display: "flex",
                      margin: "0.2rem 0.2rem",
                      color: "var(--text-color)",
                    }}
                  >
                    {tokenPrice == 0 || tokenPrice == undefined
                      ? "TBA"
                      : "1 MEIJI=" + tokenPrice + tokenSymbol}
                  </p>
                </div>
              </div>

              <div className="lower">
                <p
                  className="orionis w-11/12 opacity-70"
                  style={{ color: "var(--text-color)" }}
                >
                  {metadata?.desc}
                </p>
              </div>
            </div>
          </div>
          <div className="secondcard2 bg-no-repeat">
            <div className="cyptia__ucard-body-details">
              <div
                className="cyptia__ucard-body-details-total"
                style={{ color: "var(--text-color)" }}
              >
                <h2 className="scotia text-xs opacity-70">MIN ALLOCATION</h2>
              </div>
              <div className="cyptia__ucard-body-details-max">
                <h2 className="scotia text-xs opacity-70">Maximum</h2>
              </div>
              <div className="cyptia__ucard-body-details-access">
                <h2 className="scotia text-xs opacity-70">ACCESS</h2>
                <h1
                  className="quest text-xl"
                  style={{ color: "var(--text-color2)" }}
                >
                  {pool?.accessType ? "Private" : "Public"}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UCard;
