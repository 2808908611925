import axios from "axios";
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";

import {
  EntityKey,
  EntityKeys,
  formatGraphQuery,
  formatGraphResponse,
  GraphQueryOpts,
  InfiniteGraphQueryOpts,
  querySubgraph,
  SubgraphEntities,
  SubgraphError,
  SubgraphQueryReturnTypes,
} from "./graph";

const subgraphUrl = process.env.REACT_APP_SUBGRAPH_URL;
const staleTime = 60 * 1000; // 60 seconds

// Pass `opts = null` to prevent http request
export default function useSubgraphQuery(opts, reactQueryOptions) {
  return useQuery(["subgraph-query", opts], () => querySubgraph(opts), {
    staleTime,
    ...reactQueryOptions,
  });
}

export function useInfiniteSubgraphQuery(opts, reactQueryOptions) {
  if (!subgraphUrl) {
    // This should _only_ happen in development
    throw new Error("env.REACT_APP_SUBGRAPH_URL is missing");
  }
  return useInfiniteQuery(
    ["infinite-subgraph-query", opts],
    async ({ queryKey, pageParam = 0 }) => {
      const { pageSize, ...evaluatedOpts } = queryKey[1];

      const response = await axios.post(
        subgraphUrl,
        {
          query: formatGraphQuery({
            ...evaluatedOpts,
            skip: pageSize * pageParam,
            first: pageSize,
          }),
        },
        { headers: { "Content-Type": "application/json" } }
      );

      if ("errors" in response.data) {
        throw new Error(
          response.data.errors?.[0]?.message ||
            "Something is wrong with this Graph request"
        );
      }

      return formatGraphResponse(opts.entity, response.data?.data);
    },
    {
      staleTime,
      ...reactQueryOptions,

      // Don't allow this function to be overwritten by reactQueryOptions
      getNextPageParam: (lastPage, allPages) => {
        // If the last page contains less than the expected page size,
        // it's safe to assume you're at the end.
        if (lastPage.length < opts.pageSize) {
          return false;
        } else {
          return allPages.length;
        }
      },
    }
  );
}
