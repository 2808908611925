import { useMemo } from "react";
import useActiveWeb3React from "./useActiveWeb3React";
import { getBep20Contract, getErc721Contract } from "../utils/contractHelpers";
import { getMulticallAddress } from "../utils/addressHelpers";

// Imports below migrated from Exchange useContract.ts
import ERC20_ABI from "../config/abi/erc20.json";
import multiCallAbi from "../config/abi/Multicall.json";
import { getContract, getProviderOrSigner } from "../utils";

export const useERC20 = (address, withSignerIfPossible = true) => {
  const { library, account } = useActiveWeb3React();
  return useMemo(
    () =>
      getBep20Contract(
        address,
        withSignerIfPossible ? getProviderOrSigner(library, account) : null
      ),
    [account, address, library, withSignerIfPossible]
  );
};

/**
 * @see https://docs.openzeppelin.com/contracts/3.x/api/token/erc721
 */
export const useERC721 = (address) => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getErc721Contract(address, library.getSigner()),
    [address, library]
  );
};

// Code below migrated from Exchange useContract.ts

// returns null on errors
function useContract(address, ABI, withSignerIfPossible = true) {
  const { library, account } = useActiveWeb3React();

  return useMemo(() => {
    if (!address || !ABI || !library) return null;
    try {
      return getContract(
        address,
        ABI,
        withSignerIfPossible ? getProviderOrSigner(library, account) : null
      );
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [address, ABI, library, withSignerIfPossible, account]);
}

export function useTokenContract(tokenAddress, withSignerIfPossible) {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible);
}

export function useMulticallContract() {
  return useContract(getMulticallAddress(), multiCallAbi, false);
}
