import React, { useEffect, useState } from "react";
import "./navbar.css";
import heroButtonBg from "../../assets/default/Frame-1.png";
import user from "../../assets/images/user.png";
import ToggleBack from "../../assets/svgs/toggle.svg";
import userFrame from "../../assets/images/userFrame.png";
import { BsMoonFill, BsSunFill } from "react-icons/bs";
import { FaBars } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import useAuth from "../../hooks/useAuth";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [isScreenSmall, setIsScreenSmall] = useState(false);
  const { account, chainId } = useActiveWeb3React();
  const { login, logout } = useAuth();

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 600) setIsScreenSmall(true);
      else setIsScreenSmall(false);
    });
  }, []);

  const toggleTheme = () => {
    document.body.classList.toggle("active");
    localStorage.setItem(
      "themeLight",
      document.body.classList.contains("active")
    );
  };

  const connectHandle = () => {
    login("injected");
    window.localStorage.setItem("connectorIdv2", "injected");
  };

  return (
    <>
      {toggleMenu && (
        <nav data-aos="fade-down" className="nav">
          <div className="nav-inner">
            <button
              type="button"
              onClick={() => setToggleMenu(false)}
              className="nav-toggler"
            >
              <IoClose />
            </button>
            <ul>
              <li>
                <a
                  href="/profile"
                  onClick={() => setToggleMenu(false)}
                  className="scotia nav-item"
                >
                  Profile
                </a>
              </li>
              <li>
                <a
                  href="/staking"
                  onClick={() => setToggleMenu(false)}
                  className="scotia nav-item"
                >
                  Staking
                </a>
              </li>
              <li>
                <a
                  href="/claims"
                  onClick={() => setToggleMenu(false)}
                  className="scotia nav-item"
                >
                  Claims
                </a>
              </li>
            </ul>
          </div>
        </nav>
      )}
      <div
        data-aos="fade-down"
        className="navbar w-11/12 m-auto items-center flex justify-between gap-2"
      >
        {!toggleMenu && (
          <button
            className="toggleBars"
            type="button"
            onClick={() => setToggleMenu(true)}
          >
            <FaBars />
          </button>
        )}
        <div className="nav-left items-center flex justify-between">
          <a href="/">
            <h1
              className="scotia uppercase text-md md:text-xl sm:text-lg sm:mr-5 font-bold text-white"
              style={{ color: "var(--text-color)" }}
            >
              <img src="meijidao.png" width={200} />
            </h1>
          </a>
          <div className="nav-titles flex justify-between items-center gap-10">
            <a className="scotia" href="/staking">
              STAKING
            </a>
            <a className="scotia" href="claims">
              CLAIMS
            </a>
          </div>
        </div>

        <div className="nav-right flex justify-between gap-2 sm:gap-8 items-center">
          <div
            className="w-12 text-white flex items-center justify-center gap-2 p-7 select-none cursor-pointer"
            style={{
              backgroundImage: `url(${ToggleBack})`,
              backgroundSize: "100% 100%",
            }}
            onClick={toggleTheme}
          >
            <span className="h-full" style={{ color: "#fff" }}>
              <BsMoonFill />
            </span>
            <span className="h-full" style={{ color: "rgb(23, 0, 47)" }}>
              <BsSunFill />
            </span>
          </div>

          <button
            onClick={!account ? connectHandle : logout}
            style={{
              // backgroundImage: `url(${heroButtonBg})`,
              color: "var(--text-color)",
            }}
            className="scotia connect-wallet-btn p-3 bg-cover bg-no-repeat px-6 py-3"
          >
            {account
              ? `${account.slice(0, 6)}...${account.slice(
                  account.length - 4,
                  account.length
                )}`
              : !isScreenSmall
              ? "CONNECT WALLET"
              : "CONNECT"}
          </button>

          <div className="nav-user flex justify-between items-center gap-3">
            <div
              className="user-frame bg-no-repeat py-2 px-1"
              style={{
                backgroundSize: "100% 100%",
                backgroundImage: `url(${userFrame})`,
              }}
            >
              <a href="/profile">
                <img src={user} alt="user" />
              </a>
            </div>
            <div className="nav-user-details text-left">
              <h1
                className="quest users uppercase"
                style={{ color: "var(--text-color2)", cursor: "pointer" }}
              >
                Profile
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
