import axios from "axios";
// import { parseProjectJson } from "models/subgraph-entities/project";

// https://thegraph.com/docs/graphql-api#filtering
export const formatGraphQuery = (opts) => {
  if (!opts) return;
  let args = "";

  const addArg = (name, value) => {
    if (value === undefined) return;
    args += (args.length ? ", " : "") + `${name}: ` + value;
  };
  const formatWhere = (where) =>
    `${where.key}${where.operator ? "_" + where.operator : ""}:` +
    (Array.isArray(where.value)
      ? `[${where.value.map((v) => `${v}`).join(",")}]`
      : `${where.value}`);

  addArg("text", opts.text ? `"${opts.text}"` : undefined);
  addArg("first", opts.first);
  addArg("skip", opts.skip);
  addArg("orderBy", opts.orderBy);
  addArg("orderDirection", opts.orderDirection);
  if (opts.block) {
    if (opts.block.number) {
      addArg("block", `{ number: ${opts.block.number} }`);
    } else if (opts.block.hash) {
      addArg("block", `{ hash: ${opts.block.hash} }`);
    }
  }
  addArg(
    "where",
    opts.where
      ? Array.isArray(opts.where)
        ? `{${opts.where.map((w) => ` ${formatWhere(w)}`)} }`
        : `{ ${formatWhere(opts.where)} }`
      : undefined
  );

  return `{ ${opts.entity}${isPluralQuery(opts.entity) ? "s" : ""}${
    args ? `(${args})` : ""
  } {${opts.keys.reduce(
    (acc, key) =>
      typeof key === "string" ||
      typeof key === "number" ||
      typeof key === "symbol"
        ? acc + " " + key.toString()
        : acc + ` ${key.entity} { ${key.keys.join(" ")} }`,
    ""
  )} } }`;
};

const subgraphUrl = process.env.REACT_APP_SUBGRAPH_URL;

export const trimHexZero = (hexStr) => hexStr.replace("0x0", "0x");

export function formatGraphResponse(entity, response) {
  if (!response || typeof response !== "object") {
    return [];
  }

  // This code works perfectly, but there's an unusual TypeScript issue that
  // makes it appear type unsafe...
  //
  // For example, `response.projects` is a ProjectJson[], as dictated by
  // SubgraphQueryReturnTypes['projects']
  // We then map over that array to return a Project[], but for some reason,
  // TypeScript isn't equating `Project` with `SubgraphEntities['project']`,
  // even though they're the same type.
  //
  // If you think you can solve it and remove the @ts-ignore, be my guest.
  // My best guess is a conditional resolver type, ie:
  // type EntityResolver<E extends EntityKey> = E extends 'project' ? Project : ...
  // in favor of the main SubgraphEntities type. I tried it, though, to no avail.

  switch (entity) {
    case "pool":
      if ("pools" in response) {
        // @ts-ignore
        // return response.pools.map(parseProjectJson);
        return response.pools;
      }
      break;
  }
  return [];
}

export async function querySubgraph(opts) {
  if (!subgraphUrl) {
    // This should _only_ happen in development
    throw new Error("env.REACT_APP_SUBGRAPH_URL is missing");
  }

  if (!opts) return [];

  const response = await axios.post(
    subgraphUrl,
    {
      query: formatGraphQuery(opts),
    },
    { headers: { "Content-Type": "application/json" } }
  );

  if ("errors" in response.data) {
    throw new Error(
      (Array.isArray(response.data.errors)
        ? response.data.errors?.[0]?.message
        : response.data.errors?.message) ||
        "Something is wrong with this Graph request"
    );
  }

  return formatGraphResponse(opts.entity, response.data?.data);
}

/** Repeats a max page size query until all entities have been returned. */
export async function querySubgraphExhaustive(opts) {
  const pageSize = 1000;
  const entities = [];

  const query = async (page) => {
    if (!opts) return;

    const data = await querySubgraph({
      ...opts,
      first: pageSize,
      ...(page > 0
        ? {
            skip: pageSize * page,
          }
        : {}),
    });

    if (!data) return;

    entities.push(...data);

    if (data.length === pageSize) await query(page + 1);
  };

  await query(0);

  return entities;
}

const isPluralQuery = (key) => {
  if (key === "projectSearch") return false;

  return true;
};
