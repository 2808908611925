import React, { useEffect, useState } from "react";
import { formatUnits } from "ethers/lib/utils";
import whiteShade from "../../../assets/images/whiteBlurBG.png";
import stackingLeftCardButton from "../../../assets/images/stackingLeftCardButton.png";
import {
  useApproveCallback,
  ApprovalState,
} from "../../../hooks/useApproveCallback";
import { useStakePool } from "../../../hooks/useStakePool";
import useActiveWeb3React from "../../../hooks/useActiveWeb3React";
import { useTokenBalance } from "../../../hooks/contractReader/useTokenInfo";
import {
  usePoolUserInfo,
  usePoolPendingRewards,
  usePoolLockDays,
} from "../../../hooks/contractReader/usePoolUserInfo";
import {
  GovernanceTokenAddress,
  GovernanceTokenDecimals,
} from "../../../config";
import pools from "../../../config/constants/pools";

import StakeModal from "../../../components/Modal/StakeModal";
import Loader from "../../../components/Loader/Loader";

const PoolDetail = () => {
  const { account } = useActiveWeb3React();
  const [showStakeModal, setShowStakeModal] = useState(false);

  const [lockDuration, setLockDuration] = useState(0);

  const stake = useStakePool();
  const [approval, approveCallback] = useApproveCallback(
    1,
    GovernanceTokenAddress,
    pools[lockDuration].contractAddress
  );

  const igoBalance = useTokenBalance(GovernanceTokenAddress, account);
  const balance = formatUnits(igoBalance ?? 0, GovernanceTokenDecimals);

  const userInfo = usePoolUserInfo(
    pools[lockDuration].contractAddress,
    account
  );

  const pendingRewards = usePoolPendingRewards(
    pools[lockDuration].contractAddress,
    account
  );

  const lockDays = usePoolLockDays(pools[lockDuration].contractAddress);

  const stakedAmount = formatUnits(
    userInfo?.amount ?? 0,
    GovernanceTokenDecimals
  );
  const rewardAmount = formatUnits(
    pendingRewards ?? 0,
    GovernanceTokenDecimals
  );
  const unlockDate =
    parseInt(userInfo?.lastDepositDate.toString()) +
    60 * 60 * 24 * (lockDays ?? 0);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    if (unlockDate * 1000 > new Date().getTime()) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
    } else {
      setTimeLeft(undefined);
    }
  });

  function calculateTimeLeft() {
    const difference = unlockDate * 1000 - new Date().getTime();

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  const stakeHandle = () => {
    setShowStakeModal(true);
  };

  const withdrawHandle = () => {
    stake(1, lockDuration, 0);
  };

  const claimHandle = () => {
    stake(2, lockDuration, 0);
  };

  return (
    <div
      data-aos="fade-up"
      className="border-2 border-gray-100 border-opacity-20 rounded-lg p-3 sm:p-4 light-border bg-no-repeat bg-top"
      style={{
        backgroundImage: `url(${whiteShade})`,
        backgroundSize: "100% auto",
      }}
    >
      <p
        className="scotia text-xs sm:text-sm my-3 uppercase"
        style={{ color: "var(--text-color)" }}
      >
        lock duration
      </p>

      <div>
        <button
          onClick={() => setLockDuration(0)}
          style={{
            color: "var(--text-color)",
          }}
          className={` ${
            lockDuration === 0 ? "selected-duration" : "unselected-duration"
          } scotia stake-btn-type2 ml-0 px-3 py-1 sm:px-4 sm:py-2 text-xs
             border-2 rounded-md border-gray-100 border-opacity-20 inline-block uppercase inline-gap`}
        >
          7 days
        </button>
        <button
          onClick={() => setLockDuration(1)}
          style={{
            color: "var(--text-color)",
          }}
          className={` ${
            lockDuration === 1 ? "selected-duration" : "unselected-duration"
          } scotia stake-btn-type2 ml-0 px-3 py-1 sm:px-4 sm:py-2 text-xs
             border-2 rounded-md border-gray-100 border-opacity-20 inline-block uppercase inline-gap`}
        >
          14 days
        </button>
        <button
          onClick={() => setLockDuration(2)}
          style={{
            color: "var(--text-color)",
          }}
          className={` ${
            lockDuration === 2 ? "selected-duration" : "unselected-duration"
          } scotia stake-btn-type2 ml-0 px-3 py-1 sm:px-4 sm:py-2 text-xs
             border-2 rounded-md border-gray-100 border-opacity-20 inline-block uppercase inline-gap`}
        >
          30 days
        </button>
        <button
          onClick={() => setLockDuration(3)}
          style={{
            color: "var(--text-color)",
          }}
          className={` ${
            lockDuration === 3 ? "selected-duration" : "unselected-duration"
          } scotia stake-btn-type2 ml-0 px-3 py-1 sm:px-4 sm:py-2 text-xs
             border-2 rounded-md border-gray-100 border-opacity-20 inline-block uppercase inline-gap`}
        >
          60 days
        </button>
        <button
          onClick={() => setLockDuration(4)}
          style={{
            color: "var(--text-color)",
          }}
          className={` ${
            lockDuration === 4 ? "selected-duration" : "unselected-duration"
          } scotia stake-btn-type2 ml-0 px-3 py-1 sm:px-4 sm:py-2 text-xs
             border-2 rounded-md border-gray-100 border-opacity-20 inline-block uppercase inline-gap`}
        >
          90 days
        </button>
      </div>

      <p
        className="scotia text-xs sm:text-sm my-3 uppercase mt-5"
        style={{ color: "var(--text-color)" }}
      >
        Your staked amount : {stakedAmount} MEIJI
      </p>

      <div className="flex justify-between flex-col sm:flex-row items-start gap-5 my-6 sm:items-center">
        <p
          className="scotia text-xs sm:text-sm uppercase"
          style={{ color: "var(--text-color)" }}
        >
          Your reward amount : {rewardAmount} MEIJI
        </p>
        <p
          className="scotia text-xs sm:text-sm uppercase"
          style={{ color: "var(--text-color)" }}
        >
          {rewardAmount > 0 && (
            <button
              data-aos="fade-up"
              onClick={claimHandle}
              className=" selected-duration scotia stake-btn-type2 ml-0 px-3 py-1 sm:px-4 sm:py-2 text-xs
              border-2 rounded-md border-gray-100 border-opacity-20 inline-block uppercase inline-gap"
            >
              Claim
            </button>
          )}
        </p>
      </div>

      <div className="flex justify-between flex-col sm:flex-row items-start gap-5 my-6 sm:items-center">
        <p
          className="scotia text-xs sm:text-sm uppercase"
          style={{ color: "var(--text-color)" }}
        >
          APY Rate : {5 + 6 * lockDuration}%
        </p>
        <p
          className="scotia text-xs sm:text-sm uppercase"
          style={{ color: "var(--text-color)" }}
        >
          {timeLeft &&
            "Maturity Date : " +
              timeLeft.days +
              " days " +
              timeLeft.hours +
              " hours " +
              timeLeft.minutes +
              " minutes " +
              timeLeft.seconds +
              " seconds"}
        </p>
      </div>

      <p
        className="scotia text-xs sm:text-sm uppercase my-9"
        style={{ color: "var(--text-color)" }}
      >
        Balance : {balance?.toString()} MEIJI
      </p>
      <div style={{ display: "flex" }}>
        <button
          data-aos="fade-up"
          style={{
            backgroundImage: `url(${stackingLeftCardButton})`,
            backgroundSize: "100% 100%",
            color: "var(--text-color)",
            margin: "5px",
          }}
          onClick={
            approval != ApprovalState.APPROVED ? approveCallback : stakeHandle
          }
          className="scotia stake-btn-type3 bg-no-repeat w-full py-3 sm:py-5 text-lg uppercase"
        >
          {approval != ApprovalState.APPROVED ? "Approve" : "Stake"}
        </button>

        {stakedAmount > 0 && (
          <button
            data-aos="fade-up"
            style={{
              backgroundImage: `url(${stackingLeftCardButton})`,
              backgroundSize: "100% 100%",
              color: "var(--text-color)",
              margin: "5px",
            }}
            disabled={unlockDate * 1000 > new Date().getTime()}
            onClick={withdrawHandle}
            className="scotia stake-btn-type3 bg-no-repeat w-full py-3 sm:py-5 text-lg uppercase"
          >
            Withdraw
          </button>
        )}
      </div>
      <StakeModal
        type={0}
        poolId={lockDuration}
        isModelOpen={showStakeModal}
        closeModalFn={() => setShowStakeModal(false)}
      />
      {!lockDays && <Loader />}
    </div>
  );
};

export default PoolDetail;
