import useContractReader from "./ContractReader";
import { LaunchpadAddress } from "../../config";
/** Returns total supply of tokens for project with `projectId`. */
export function useTotalStakingBalance(account) {
  return useContractReader({
    contract: LaunchpadAddress,
    contractName: "launchpad",
    functionName: "getStakingBalnce",
    args: [account],
  });
}

export function useTierLevelForPool(poolId, account) {
  return useContractReader({
    contract: LaunchpadAddress,
    contractName: "launchpad",
    functionName: "getTierLevel",
    args: [poolId, account],
  });
}

export function usePoolInfo(poolId) {
  return useContractReader({
    contract: LaunchpadAddress,
    contractName: "launchpad",
    functionName: "loadPool",
    args: [poolId],
    updateOn: [
      {
        contract: LaunchpadAddress,
        contractName: "launchpad",
        eventName: "Bid",
        topics: null,
      },
      {
        contract: LaunchpadAddress,
        contractName: "launchpad",
        eventName: "Modify",
        topics: null,
      },
      {
        contract: LaunchpadAddress,
        contractName: "launchpad",
        eventName: "IssueToken",
        topics: null,
      },
      {
        contract: LaunchpadAddress,
        contractName: "launchpad",
        eventName: "AllowPool",
        topics: null,
      },
    ],
  });
}
