import axios from "axios";
import { ipfsCidUrl } from "../utils/ipfs";
import { useQuery } from "react-query";

export default function usePoolMetadata(uri) {
  return useQuery(
    ["project-metadata", uri],
    async () => {
      if (!uri) {
        throw new Error("Project URI not specified.");
      }
      const url = ipfsCidUrl(uri);
      const response = await axios.get(url);
      return response.data;
    },
    {
      enabled: !!uri,
      staleTime: 60000,
    }
  );
}
