import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { ClaimHero } from "../../components/Claims/ClaimHero";
import ClaimCard from "../../components/Claims/ClaimCard";
import Pagination from "../../components/Claims/Pagination";
import useProjects from "../../hooks/contractReader/useProjects";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import { usePoolsQuery } from "../../hooks/useGraph";
import { ipfsCidUrl } from "../../utils/ipfs";
import "./index.css";

export default function Claims({ currentTime }) {
  const loadMoreContainerRef = useRef(null);
  const { account } = useActiveWeb3React();

  const [pageSize, setPageSize] = useState(10);

  const {
    data: pages,
    isLoading: isLoadingProjects,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = usePoolsQuery({
    pageSize,
    orderDirection: "desc",
    currentTime: currentTime,
    poolType: 2,
    account,
  });
  const pools = pages?.pages?.reduce((prev, group) => [...prev, ...group], []);

  // const projects = useProjects();

  // useEffect(async () => {
  //   const filteredPools = await Promise.all(
  //     projects &&
  //       projects.length > 0 &&
  //       projects
  //         .filter(
  //           (poolData) =>
  //             Number(poolData.bidEndTime) != 0 &&
  //             Date.now() > Number(poolData.bidEndTime)
  //         )
  //         .map(async (poolData) => {
  //           const poolInfoUrl = poolData.poolInfoUrl;
  //           try {
  //             const url = ipfsCidUrl(poolInfoUrl);
  //             const meta = await axios.get(url);
  //             const pool = {
  //               id: poolData.id,
  //               owner: poolData.owner,
  //               picture: ipfsCidUrl(poolData.logoUrl),
  //               phase: "1st phase",
  //               name: meta?.data.projectName,
  //               price: Number(poolData.tokenPrice),
  //               priceDecimal: Number(poolData.tokenDecimals),
  //               desc: meta?.data.projectSpecial,
  //               totalRaised: formatUnits(poolData.totalRaised.toString()),
  //               claimed: formatUnits(poolData.claimed.toString()),
  //               access: poolData.accessType ? "Private" : "Public",
  //               // bidStartTime: Number(poolData.bidStartTime),
  //               bidEndTime: Number(poolData.bidEndTime),
  //               tokenAddress: poolData.tokenAddress,
  //             };
  //             return pool;
  //           } catch (e) {
  //             console.log(e);
  //           }
  //         })
  //   );

  //   setPools(filteredPools);
  // }, [projects]);

  return (
    <>
      <div className="py-10 pb-16 sm:pb-36 claims-bg min-h-screen">
        {/* <ClaimHero /> */}
        <div className="sm:w-10/12 w-11/12 mx-auto pb-10 sm:pb-16">
          {pools &&
            pools.length > 0 &&
            pools.map((pool, index) => <ClaimCard pool={pool} key={index} />)}
        </div>
        <Pagination />
      </div>
    </>
  );
}
