import React from "react";
import frameheading from "../../assets/images/frameheading.png";
import line from "../../assets/images/line.png";
import "./heading.css";

export const Heading = ({ heading, toggle, expand }) => {
  return (
    <div
      data-aos="fade-up"
      className="flex items-center justify-between my-3"
      style={{ fontFamily: "Quest" }}
    >
      <h1
        className="quest uppercase text-sm md:text-2xl sm:text-xl"
        style={{ color: "var(--text-color)" }}
      >
        {heading}
      </h1>

      <div
        style={{
          background: `url(${line})`,
          height: "0.5rem",
          width: "70%",
        }}
      ></div>

      <div
        className="px-4 pt-1 pb-2 pool-expand-btn"
        style={{
          background: `url(${frameheading})`,
          backgroundSize: "100% 100%",
        }}
      >
        <button
          type="button"
          className="scotia text-black uppercase text-xs whitespace-nowrap custom-btn"
          style={{ fontFamily: "Quest" }}
          onClick={toggle}
        >
          All pools{" "}
          <span
            style={expand ? { transform: "rotateZ(90deg)" } : {}}
            className="text-black inline-block"
          >
            &gt;
          </span>
        </button>
      </div>
    </div>
  );
};
