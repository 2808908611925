/* global BigInt */
import React, { useState } from "react";
import Modal from "react-modal";
import { parseUnits } from "ethers/lib/utils";
import heroButtonBg from "../../assets/default/Frame-1.png";
import Loader from "../Loader/Loader";
import { GovernanceTokenDecimals } from "../../config";
import { useStakeFarm } from "../../hooks/useStakeFarm";

Modal.setAppElement("#root");

function FarmStakeModal({ type, isModelOpen, closeModalFn }) {
  function afterOpenModal() {}

  function closeModal() {
    closeModalFn();
  }

  const [tokenAmount, setTokenAmount] = useState("0");
  const [showLoading, setShowLoading] = useState(false);

  const stake = useStakeFarm();

  async function handleSubmit(e) {
    e.preventDefault();
    setShowLoading(true);

    stake(type, parseUnits(tokenAmount, GovernanceTokenDecimals));

    setShowLoading(false);
    closeModal();
  }

  return (
    <div>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={isModelOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            width: "60%",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        contentLabel="Example Modal"
      >
        {/* {showLoading && <Loader />} */}
        <h2>{type === 0 ? "Stake" : type === 1 ? "Withdraw" : "Claim"}</h2>
        <div className="custom-modal-content" style={{ minHeight: 120 }}>
          {showLoading ? (
            <Loader />
          ) : (
            <form className="form-form" onSubmit={handleSubmit}>
              {type !== 2 && (
                <div className="mb-0">
                  <label
                    className="orionis text-xs"
                    style={{ color: "var(--text-color)" }}
                  >
                    LP Token Amount: *
                  </label>
                  <input
                    value={tokenAmount}
                    onChange={(e) => setTokenAmount(e.target.value)}
                    className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                    style={{ backgroundColor: "var(--bg-color)" }}
                    type="text"
                    placeholder="Type your answer here"
                  />
                </div>
              )}

              <div className="mt-4 w-full text-left">
                <button
                  style={{
                    backgroundImage: `url(${heroButtonBg})`,
                    backgroundSize: "100% 100%",
                    color: "var(--text-color)",
                  }}
                  // onClick={onSubmit}

                  disabled={!tokenAmount}
                  className="quest bg-no-repeat px-6 py-3 max-w-full w-52"
                >
                  Confirm
                </button>
              </div>
            </form>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default FarmStakeModal;
