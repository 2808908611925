import React from "react";
import { Web3ReactProvider } from "@web3-react/core";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { useThemeManager } from "./state/user/hooks";
import { getLibrary } from "./utils/web3React";
import { RefreshContextProvider } from "./contexts/RefreshContext";
import ThemeProvider from "./contexts/ThemeContext";
import ToastsProvider from "./contexts/ToastsContext/Provider";
import store from "./state";

const queryClient = new QueryClient();

const Providers = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          {/* <ToastsProvider> */}
          <ThemeProvider>
            {/* <RefreshContextProvider> */}
            {children}
            {/* </RefreshContextProvider> */}
          </ThemeProvider>
          {/* </ToastsProvider> */}
        </QueryClientProvider>
      </Provider>
    </Web3ReactProvider>
  );
};

export default Providers;
