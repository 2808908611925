import "./index.css";
import FarmDetail from "./components/farm";
import PoolDetail from "./components/pools";

import detailsTopLeftCard from "../../assets/images/detailsTopLeftCard.png";
import { useEffect, useState } from "react";

const Stake = () => {
  const [selectedCategory, setSelectedCategory] = useState("stake");
  return (
    <>
      <div className="pt-10 pb-0 sm:pb-0 stake-bg ">
        <div className="w-11/12 mx-auto flex justify-between items-start stake-container">
          <div className="flex-w-half">
            <h1
              data-aos="fade-up"
              className="scotia font-extrabold text-3xl sm:text-4xl mb-5"
              style={{ color: "var(--text-color)" }}
            >
              Stake your MEIJI tokens to join moonshot IDO's.
            </h1>
            <p
              data-aos="fade-up"
              className="orionis text-sm md:text-base"
              style={{ color: "var(--text-color)" }}
            >
              Stake your $MEIJI tokens to access your tier level for presales
              hosted on our platform. Furthermore, enjoy APY % returns on your
              staked tokens. A win-win situation as always with the mage army.
              #MEIJIARMY
            </p>
          </div>

          <div className="flex-w-half">
            <div
              className="p-5 sm:p-4 mb-20  bg-no-repeat rounded-xl stake-left-card"
              style={{
                backgroundImage: `url(${detailsTopLeftCard})`,
                backgroundSize: "100% 100%",
              }}
            >
              <div data-aos="fade-up" className="mb-2">
                <button
                  onClick={() => setSelectedCategory("Stake")}
                  style={{
                    backgroundSize: "100% 100%",
                    color: "var(--text-color)",
                  }}
                  className={`  
                  ${
                    selectedCategory &&
                    selectedCategory.toLowerCase() === "stake"
                      ? "selected-stake-category"
                      : "unselected-stake-category"
                  } 
                  scotia stake-btn-type1 bg-no-repeat px-4 py-2 sm:px-6 sm:py-3 text-xs inline-block uppercase inline-gap`}
                >
                  Stake
                </button>
                <button
                  onClick={() => setSelectedCategory("farm")}
                  style={{
                    backgroundSize: "100% 100%",
                    color: "var(--text-color)",
                  }}
                  className={`  
                  ${
                    selectedCategory &&
                    selectedCategory.toLowerCase() === "farm"
                      ? "selected-stake-category"
                      : "unselected-stake-category"
                  } 
                  scotia stake-btn-type1 bg-no-repeat px-4 py-2 sm:px-6 sm:py-3 text-xs inline-block uppercase inline-gap`}
                >
                  farm
                </button>
              </div>

              {selectedCategory && selectedCategory.toLowerCase() === "farm" ? (
                <FarmDetail />
              ) : (
                <PoolDetail />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stake;
