import useContractReader from "./ContractReader";
import { LPTokenAddress } from "../../config";
/** Returns total supply of tokens for project with `projectId`. */
export function useMeijiPrice() {
  const reserves = useContractReader({
    contract: LPTokenAddress,
    contractName: "lp",
    functionName: "getReserves",
    args: [],
  });

  if (reserves && reserves[0] > 0) return parseFloat(reserves[1] / reserves[0]);
  else return 0;
}
