import { BigNumber } from "@ethersproject/bignumber";
import { Contract } from "@ethersproject/contracts";
import { getAddress } from "@ethersproject/address";
import { AddressZero } from "@ethersproject/constants";
import { simpleRpcProvider } from "./providers";
import ERC20TokenABI from "../config/abi/erc20.json";
import { LaunchpadAddress, FarmAddress } from "../config/index";
import LaunchPadABI from "../config/abi/launchpad.json";
import StakePoolABI from "../config/abi/stakepool.json";
import FarmABI from "../config/abi/farm.json";
import pools from "../config/constants/pools";
// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value) {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

// add 10%
export function calculateGasMargin(value) {
  return value
    .mul(BigNumber.from(10000).add(BigNumber.from(1000)))
    .div(BigNumber.from(10000));
}

// account is not optional
export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

// account is optional
export function getContract(address, ABI, signer) {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, signer ?? simpleRpcProvider);
}

export function getLaunchPadContract(library, account) {
  return getContract(
    LaunchpadAddress,
    LaunchPadABI,
    getProviderOrSigner(library, account)
  );
}

export function getStakePoolContract(library, account, poolId) {
  return getContract(
    pools[poolId].contractAddress,
    StakePoolABI,
    getProviderOrSigner(library, account)
  );
}

export function getFarmContract(library, account) {
  return getContract(
    FarmAddress,
    FarmABI,
    getProviderOrSigner(library, account)
  );
}
