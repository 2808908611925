import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleTheme as toggleThemeAction } from "../actions";
import { GAS_PRICE_GWEI } from "./helpers";
import { ChainId } from "../../../config";

import { updateGasPrice } from "../actions";

export function useThemeManager() {
  const dispatch = useDispatch();
  const isDark = useSelector((state) => state.user.isDark);

  const toggleTheme = useCallback(() => {
    dispatch(toggleThemeAction());
  }, [dispatch]);

  return [isDark, toggleTheme];
}

export function useGasPrice() {
  const chainId = process.env.REACT_APP_CHAIN_ID;
  const userGas = useSelector((state) => state.user.gasPrice);
  return chainId == ChainId.MAINNET ? userGas : GAS_PRICE_GWEI.testnet;
}

export function useGasPriceManager() {
  const dispatch = useDispatch();
  const userGasPrice = useGasPrice();

  const setGasPrice = useCallback(
    (gasPrice) => {
      dispatch(updateGasPrice({ gasPrice }));
    },
    [dispatch]
  );

  return [userGasPrice, setGasPrice];
}
