import { useEffect, useState } from "react";
import { AdminAddress } from "../config";
import {
  EntityKeys,
  GraphQueryOpts,
  InfiniteGraphQueryOpts,
  querySubgraphExhaustive,
  WhereConfig,
} from "../utils/graph";
import useSubgraphQuery, {
  useInfiniteSubgraphQuery,
} from "../utils/SubgraphQuery";

const staleTime = 60 * 1000; // 60 seconds

const keys = [
  "id",
  "poolId",
  "poolInfoUrl",
  "logoUrl",
  "owner",
  "bidEndTime",
  "accessType",
  "tokenPrice",
  "tokenAddress",
  "tokenPriceDecimals",
  "tokenTotalSupply",
  "totalRaised",
  "claimed",
];

const queryOpts = (opts) => {
  const where = [];

  if (
    opts.poolType == 0 ||
    opts.poolType == 1 ||
    opts.poolType == 2 ||
    opts.poolType == 3 ||
    opts.poolType == 4 ||
    opts.poolType == 5
  ) {
    where.push({
      key: "allowed",
      value: true,
    });
  } else {
    where.push({
      key: "allowed",
      value: false,
    });
  }

  if (opts.poolType == 0 || opts.poolType == 3 || opts.poolType == 6) {
    where.push({
      key: "issued",
      value: false,
    });
  }

  if (opts.poolType == 1 || opts.poolType == 4) {
    where.push({
      key: "issued",
      value: true,
    });

    where.push({
      key: "bidEndTime",
      value: opts.currentTime,
      operator: "gte",
    });
  }

  if (opts.poolType == 2 || opts.poolType == 5) {
    where.push({
      key: "issued",
      value: true,
    });

    where.push({
      key: "bidEndTime",
      value: opts.currentTime,
      operator: "lt",
    });

    // where.push({
    //   key: "completed",
    //   value: true,
    // });
  }

  if (
    opts.poolType == 3 ||
    opts.poolType == 4 ||
    opts.poolType == 5 ||
    opts.poolType == 6
  ) {
    where.push({
      key: "owner",
      value: `"${opts.account}"`,
    });
  }

  where.push({
    key: "removed",
    value: false,
  });

  return {
    entity: "pool",
    keys: opts.keys ?? keys,
    orderDirection: opts.orderDirection ?? "desc",
    orderBy: opts.orderBy ?? "id",
    pageSize: opts.pageSize,
    where,
  };
};

export function usePoolsQuery(opts) {
  return useInfiniteSubgraphQuery(queryOpts(opts), {
    staleTime,
  });
}
