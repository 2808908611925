import React, { useState, createContext } from "react";

export const ThemeContext = createContext({
  theme: "darkTheme",
  isDarkMode: true,
  setThemeName: () => {},
});

const ThemeProvider = (props) => {
  // Read current theme from localStorage or maybe from an api
  const curThemeName = localStorage.getItem("appTheme") || "darkTheme";

  const setThemeName = (themeName) => {
    localStorage.setItem("appTheme", themeName);
  };

  return (
    <ThemeContext.Provider
      value={{
        theme: curThemeName,
        isDarkMode: curThemeName == "darkTheme",
        setThemeName: setThemeName,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
