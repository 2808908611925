import React, { useState, useEffect, useRef } from "react";
import { Heading } from "./header/Heading";
import UCard from "../components/ucard/UCard";

import useActiveWeb3React from "../hooks/useActiveWeb3React";
import { usePoolsQuery } from "../hooks/useGraph";

const CharacterizedPools = ({ heading, poolType, currentTime }) => {
  const { account } = useActiveWeb3React();

  const [pageSize, setPageSize] = useState(10);

  const loadMoreContainerRef = useRef(null);

  const {
    data: pages,
    isLoading: isLoadingProjects,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = usePoolsQuery({
    pageSize,
    orderDirection: "desc",
    currentTime: currentTime,
    poolType: poolType,
    account,
  });

  const pools = pages?.pages?.reduce((prev, group) => [...prev, ...group], []);

  const [collapse1, setCollapse1] = useState(false);

  const toggle = () => {
    setCollapse1(!collapse1);
  };

  // When we scroll within 200px of our loadMoreContainerRef, fetch the next page.
  // useEffect(() => {
  //   if (loadMoreContainerRef.current) {
  //     const observer = new IntersectionObserver(
  //       (entries) => {
  //         if (entries.find((e) => e.isIntersecting) && hasNextPage) {
  //           fetchNextPage();
  //         }
  //       },
  //       {
  //         rootMargin: "200px",
  //       }
  //     );
  //     observer.observe(loadMoreContainerRef.current);

  //     return () => observer.disconnect();
  //   }
  // }, [fetchNextPage, hasNextPage]);

  return (
    <div>
      <div className="features">
        <Heading heading={heading} toggle={toggle} expand={collapse1} />

        {collapse1 ? (
          <>
            <div className="featureCard">
              {pools &&
                pools.map((pool, i) => (
                  <a href={`/pool?id=${pool.id}`} key={i}>
                    <UCard poolType={poolType} pool={pool} />
                  </a>
                ))}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CharacterizedPools;
