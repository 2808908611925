import { createReducer } from "@reduxjs/toolkit";
import { GAS_PRICE_GWEI } from "./hooks/helpers";
import { toggleTheme, updateGasPrice } from "./actions";

export const initialState = {
  isDark: false,
  gasPrice: GAS_PRICE_GWEI.default,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(toggleTheme, (state) => {
      state.isDark = !state.isDark;
    })
    .addCase(updateGasPrice, (state, action) => {
      state.gasPrice = action.payload.gasPrice;
    })
);
