import React from "react";
import "./loader.css";
import LoaderIcon from "../../assets/loader.png";
function Loader() {
  return (
    <div className="loader" style={{ minHeight: 100 }}>
      <img src={LoaderIcon} alt="" />
      <p>Loading...</p>
    </div>
  );
}

export default Loader;
