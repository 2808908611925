import "./form.css";
import heroButtonBg from "../assets/default/Frame-1.png";
import { AiOutlineGithub } from "react-icons/ai";
import { BsInstagram } from "react-icons/bs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { SiDiscord } from "react-icons/si";
import { IoLogoTwitter } from "react-icons/io";
import formFormBg from "../assets/images/formFormBg.png";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { ethers } from "ethers";

import { LaunchpadAddress } from "../config";
import LaunchPadABI from "../config/abi/launchpad.json";
import Loader from "../components/Loader/Loader";

import { pinJSONToIpfs, IPFS_TAGS, pinFileToIpfs } from "../utils/ipfs";

const client = ipfsHttpClient("https://ipfs.infura.io:5001/api/v0");

const Form = () => {
  useEffect(() => {
    document.body.style.backgroundImage = "none";
  }, []);

  const [formInput, updateFormInput] = useState({
    name: "test",
    email: "test@126.com",
    tgUsername: "@test",
    projectName: "tset",
    logo: "",
    projectWebsite: "https://test.com",
    projectDescription: "test",
    video: "",
    projectSpecial: "test",
    devStage: "dev",
    whitepaperLink: "https://test.com",
    twitter: "test",
  });
  const [showLoading, setShowLoading] = useState(false);
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [descriptionHTML, setDescriptionHTML] = useState("test");
  const navigate = useNavigate();

  async function createPool() {
    const {
      name,
      email,
      tgUsername,
      projectName,
      projectWebsite,
      projectDescription,
      projectSpecial,
      devStage,
      whitepaperLink,
      walletAddress,
      twitter,
    } = formInput;
    const { video } = formInput;
    const { logo } = formInput;

    /* first, upload to IPFS */
    try {
      setShowLoading(true);
      // const added = await client.add(data);
      // // const addedVideo = await client.add(video);
      // const addedLogo = await client.add(logo);
      // const dataUrl = `https://ipfs.infura.io/ipfs/${added.path}`;
      // // const videoUrl = `https://ipfs.infura.io/ipfs/${addedVideo.path}`;
      // const logoUrl = `https://ipfs.infura.io/ipfs/${addedLogo.path}`;

      const logoMetadata = await pinFileToIpfs(logo, {
        metadata: { tag: IPFS_TAGS.LOGO },
      });

      const dataInfo = {
        name,
        email,
        tgUsername,
        projectName,
        projectWebsite,
        projectDescription,
        projectSpecial,
        devStage,
        whitepaperLink,
        walletAddress,
        twitter,
      };

      const uploadedMetadata = await pinJSONToIpfs(
        {
          ...dataInfo,
          version: 3,
        },
        {
          name: "igo-metadata.json",
          metadata: {
            tag: IPFS_TAGS.LOGO,
          },
        }
      );

      if (!uploadedMetadata.success) {
        return;
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const launchpadContract = new ethers.Contract(
        LaunchpadAddress,
        LaunchPadABI,
        signer
      );

      let transaction = await launchpadContract.addPool(
        uploadedMetadata.cid,
        logoMetadata.cid
      );
      await transaction.wait();
      navigate("/");

      /* after file is uploaded to IPFS, pass the URL to save it on Polygon */
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
    setShowLoading(false);
  }

  const onEditorStateChange = (editorState) => {
    setDescription(editorState);
    setDescriptionHTML(
      draftToHtml(convertToRaw(description.getCurrentContent()))
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    createPool();
  };

  return (
    <>
      {showLoading && <Loader />}
      <div className="flex justify-center items-center py-20 bg-no-repeat form-bg flex-col text-center">
        <div data-aos="fade-down" className="mb-10 px-5">
          <h1
            className="scotia uppercase text-3xl mb-5 font-bold text-white"
            style={{ color: "var(--text-color)" }}
          >
            <Link to="/">
              <span>MeijiDAO</span>
            </Link>
          </h1>
          <p
            data-aos="fade-down"
            className="quest uppercase text-base mb-2"
            style={{ color: "var(--text-color)" }}
          >
            application form
          </p>
          <p
            data-aos="fade-down"
            className="orionis uppercase text-xs mb-2"
            style={{ color: "var(--text-color)" }}
          >
            For game developers, and metaverse team
          </p>
        </div>
        <div className="w-11/12 max-w-3xl text-center">
          <div
            className="shadow-2xl p-5 sm:p-10 rounded-lg mb-20 bg-no-repeat form-form-bg"
            style={{
              backgroundImage: `url(${formFormBg})`,
              backgroundSize: "100% 100%",
            }}
          >
            <div className="text-left">
              <form className="form-form" onSubmit={handleSubmit}>
                <div data-aos="fade-up" className="mb-10">
                  <label
                    className="orionis text-xs"
                    style={{ color: "var(--text-color)" }}
                  >
                    First and last name : *
                  </label>
                  <input
                    data-aos="fade-up"
                    className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                    style={{ backgroundColor: "var(--bg-color)" }}
                    type="text"
                    value={formInput.name}
                    placeholder="Type your answer here"
                    onChange={(e) =>
                      updateFormInput({ ...formInput, name: e.target.value })
                    }
                  />
                </div>

                <div data-aos="fade-up" className="mb-10">
                  <label
                    className="orionis text-xs"
                    style={{ color: "var(--text-color)" }}
                  >
                    Contact email : *
                  </label>
                  <input
                    data-aos="fade-up"
                    className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                    style={{ backgroundColor: "var(--bg-color)" }}
                    type="email"
                    value={formInput.email}
                    placeholder="name@launchpad.com"
                    onChange={(e) =>
                      updateFormInput({ ...formInput, email: e.target.value })
                    }
                  />
                </div>

                <div data-aos="fade-up" className="mb-10">
                  <label
                    className="orionis text-xs"
                    style={{ color: "var(--text-color)" }}
                  >
                    Telegram username : *
                  </label>
                  <input
                    data-aos="fade-up"
                    className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                    style={{ backgroundColor: "var(--bg-color)" }}
                    type="text"
                    value={formInput.tgUsername}
                    placeholder="Type your answer here"
                    onChange={(e) =>
                      updateFormInput({
                        ...formInput,
                        tgUsername: e.target.value,
                      })
                    }
                  />
                </div>

                <div data-aos="fade-up" className="mb-10">
                  <label
                    className="orionis text-xs"
                    style={{ color: "var(--text-color)" }}
                  >
                    Project name : *
                  </label>
                  <input
                    data-aos="fade-up"
                    className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                    style={{ backgroundColor: "var(--bg-color)" }}
                    type="text"
                    value={formInput.projectName}
                    placeholder="Type your answer here"
                    onChange={(e) =>
                      updateFormInput({
                        ...formInput,
                        projectName: e.target.value,
                      })
                    }
                  />
                </div>
                <div data-aos="fade-up" className="mb-10">
                  <label
                    className="orionis text-xs"
                    style={{ color: "var(--text-color)" }}
                  >
                    Project Subtitle : *
                  </label>
                  <input
                    data-aos="fade-up"
                    className="resize orionis w-full p-3 rounded-md text-gray-400 text-sm"
                    style={{ backgroundColor: "var(--bg-color)" }}
                    type="text"
                    value={formInput.projectSpecial}
                    placeholder="Type your answer here"
                    onChange={(e) =>
                      updateFormInput({
                        ...formInput,
                        projectSpecial: e.target.value,
                      })
                    }
                  />
                </div>

                <div data-aos="fade-up" className="mb-10">
                  <label
                    className="orionis text-xs"
                    style={{ color: "var(--text-color)" }}
                  >
                    Project Description? : *
                  </label>
                  <div
                    className="editor-div resize orionis w-full rounded-md text-gray-400 text-sm"
                    style={{
                      width: "100%",
                      height: "200px",

                      // backgroundColor: "var(--bg-color)",
                    }}
                  >
                    <Editor
                      editorState={description}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      onEditorStateChange={onEditorStateChange}
                    />
                  </div>
                </div>

                <div data-aos="fade-up" className="mb-10">
                  <label
                    className="orionis text-xs"
                    style={{ color: "var(--text-color)" }}
                  >
                    Project logo :
                  </label>
                  <input
                    data-aos="fade-up"
                    className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                    style={{ backgroundColor: "var(--bg-color)" }}
                    type="file"
                    placeholder="Upload your file here"
                    onChange={(e) =>
                      updateFormInput({ ...formInput, logo: e.target.files[0] })
                    }
                  />
                </div>

                <div data-aos="fade-up" className="mb-10">
                  <label
                    className="orionis text-xs"
                    style={{ color: "var(--text-color)" }}
                  >
                    Project website : *
                  </label>
                  <input
                    data-aos="fade-up"
                    className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                    style={{ backgroundColor: "var(--bg-color)" }}
                    type="url"
                    value={formInput.projectWebsite}
                    placeholder="Type your answer here"
                    onChange={(e) =>
                      updateFormInput({
                        ...formInput,
                        projectWebsite: e.target.value,
                      })
                    }
                  />
                </div>

                <div data-aos="fade-up" className="mb-10">
                  <label
                    className="orionis text-xs"
                    style={{ color: "var(--text-color)" }}
                  >
                    Do you have a gameplay video? (If yes, kindly post it)
                  </label>
                  <input
                    data-aos="fade-up"
                    className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                    style={{ backgroundColor: "var(--bg-color)" }}
                    type="file"
                    value={formInput.video}
                    placeholder="Type your answer here"
                    onChange={(e) =>
                      updateFormInput({
                        ...formInput,
                        video: e.target.files[0],
                      })
                    }
                  />
                </div>

                <div data-aos="fade-up" className="mb-10">
                  <label
                    className="orionis text-xs"
                    style={{ color: "var(--text-color)" }}
                  >
                    What stage of development you are right now? : *
                  </label>
                  <input
                    data-aos="fade-up"
                    className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                    style={{ backgroundColor: "var(--bg-color)" }}
                    type="text"
                    value={formInput.devStage}
                    placeholder="Type your answer here"
                    onChange={(e) =>
                      updateFormInput({
                        ...formInput,
                        devStage: e.target.value,
                      })
                    }
                  />
                </div>

                <div data-aos="fade-up" className="mb-10">
                  <label
                    className="orionis text-xs"
                    style={{ color: "var(--text-color)" }}
                  >
                    Whitepaper, litepaper and pitch desk links : *
                  </label>
                  <input
                    data-aos="fade-up"
                    className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                    style={{ backgroundColor: "var(--bg-color)" }}
                    type="url"
                    value={formInput.whitepaperLink}
                    placeholder="Type your answer here"
                    onChange={(e) =>
                      updateFormInput({
                        ...formInput,
                        whitepaperLink: e.target.value,
                      })
                    }
                  />
                </div>

                <div data-aos="fade-up" className="mb-10">
                  <label
                    className="orionis text-xs"
                    style={{ color: "var(--text-color)" }}
                  >
                    Wallet address : *
                  </label>
                  <input
                    data-aos="fade-up"
                    className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                    style={{ backgroundColor: "var(--bg-color)" }}
                    type="text"
                    value={formInput.walletAddress}
                    placeholder="Type your answer here"
                    onChange={(e) =>
                      updateFormInput({
                        ...formInput,
                        walletAddress: e.target.value,
                      })
                    }
                  />
                </div>

                <div data-aos="fade-up" className="mb-10">
                  <label
                    className="orionis text-xs"
                    style={{ color: "var(--text-color)" }}
                  >
                    Your project's twitter : *
                  </label>
                  <input
                    data-aos="fade-up"
                    className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                    style={{ backgroundColor: "var(--bg-color)" }}
                    type="text"
                    value={formInput.twitter}
                    placeholder="Type your answer here"
                    onChange={(e) =>
                      updateFormInput({
                        ...formInput,
                        twitter: e.target.value,
                      })
                    }
                  />
                </div>

                <div data-aos="fade-up" className="mb-10">
                  <p
                    className="orionis text-sm"
                    style={{ color: "var(--text-color)" }}
                  >
                    Thanks for applying to launchpad.
                  </p>
                  <p
                    data-aos="fade-up"
                    className="orionis text-xs text-opacity-5"
                    style={{ color: "var(--text-color)" }}
                  >
                    We appreciate your time.
                  </p>
                </div>

                <div data-aos="fade-up" className="w-full text-left">
                  <button
                    style={{
                      backgroundImage: `url(${heroButtonBg})`,
                      backgroundSize: "100% 100%",
                      color: "var(--text-color)",
                    }}
                    type="submit"
                    className="quest bg-no-repeat px-6 py-3 max-w-full w-52"
                  >
                    SUBMIT
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" className="mb-20 px-5 max-w-3xl">
          <p
            className="orionis text-xs mb-2 text-opacity-7"
            style={{ color: "var(--text-color)" }}
          >
            <span>MeijiDAO Inc.</span> is a registered trademark of{" "}
            <span>MeijiDAO Inc.</span> All Rights Reserved.
          </p>
          <p
            data-aos="fade-up"
            className="orionis text-xs mb-6 text-opacity-7"
            style={{ color: "var(--text-color)" }}
          >
            All logos are registered trademarks of their respective owners. All
            contents of this document, unless otherwise credited, are copyright
            © 2021 <span>MeijiDAO Inc.</span>
          </p>
          <h3
            data-aos="fade-up"
            className="scotia text-sm mb-3 uppercase"
            style={{ color: "var(--text-color)" }}
          >
            Join our community
          </h3>
          <ul
            data-aos="fade-up"
            className="form-social-links flex justify-center gap-3 text-3xl"
            style={{ color: "var(--text-color)" }}
          >
            <li>
              <a href="#">
                <AiOutlineGithub />
              </a>
            </li>
            <li>
              <a href="#">
                <SiDiscord />
              </a>
            </li>
            <li>
              <a href="#">
                <IoLogoTwitter />
              </a>
            </li>
            <li>
              <a href="#">
                <BsInstagram />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Form;
