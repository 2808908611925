import { configureStore } from "@reduxjs/toolkit";
import { save, load } from "redux-localstorage-simple";
import cloneDeep from "lodash/cloneDeep";
import { useDispatch } from "react-redux";
import user, { initialState as userInitialState } from "./user/reducer";
import transactions, {
  initialState as transactionsInitialState,
} from "./transactions/reducer";
import multicall from "./multicall/reducer";
import blockReducer from "./block";

const PERSISTED_KEYS = ["transactions"];

const safeCloneDeep = (state) => {
  try {
    return JSON.parse(JSON.stringify(state));
  } catch (error) {
    console.error(error);
    return cloneDeep(state);
  }
};

const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: {
    block: blockReducer,
    transactions,
    user,
    multicall,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ thunk: true }),
    save({ states: PERSISTED_KEYS, debounce: 1000 }),
  ],
  preloadedState: load({
    states: PERSISTED_KEYS,
    preloadedState: {
      transactions: safeCloneDeep(transactionsInitialState),
      user: safeCloneDeep(userInitialState),
    },
  }),
});

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export const useAppDispatch = () => useDispatch();

export default store;
