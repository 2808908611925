import { MaxUint256 } from "@ethersproject/constants";
import { useCallback, useMemo, useState } from "react";
import useActiveWeb3React from "./useActiveWeb3React";
import {
  useTransactionAdder,
  useHasPendingApproval,
} from "../state/transactions/hooks";
import { calculateGasMargin } from "../utils";
import { useTokenContract } from "./useContract";
import { useCallWithGasPrice } from "./useCallWithGasPrice";
import { useTokenAllowance } from "./contractReader/useTokenInfo";
// import useTokenAllowance from "./useTokenAllowance";

export const ApprovalState = {
  UNKNOWN: 0,
  NOT_APPROVED: 1,
  PENDING: 2,
  APPROVED: 3,
};

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(amountToApprove, token, spender) {
  const { account } = useActiveWeb3React();
  const { callWithGasPrice } = useCallWithGasPrice();

  const currentAllowance = useTokenAllowance(
    token ?? undefined,
    account ?? undefined,
    spender
  );
  const pendingApproval = useHasPendingApproval(token, spender);
  // check the current approval status
  const approvalState = useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN;
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN;
    // amountToApprove will be defined if currentAllowance is
    return parseInt(currentAllowance) < amountToApprove
      ? pendingApproval
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED;
  }, [amountToApprove, currentAllowance, pendingApproval, spender]);

  const tokenContract = useTokenContract(token);
  const addTransaction = useTransactionAdder();

  const approve = useCallback(async () => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error("approve was called unnecessarily");
      return;
    }
    if (!token) {
      console.error("no token");
      return;
    }

    if (!tokenContract) {
      console.error("tokenContract is null");
      return;
    }

    if (!amountToApprove) {
      console.error("missing amount to approve");
      return;
    }

    if (!spender) {
      console.error("no spender");
      return;
    }

    let useExact = false;

    const estimatedGas = await tokenContract.estimateGas
      .approve(spender, MaxUint256)
      .catch(() => {
        // general fallback for tokens who restrict approval amounts
        useExact = true;
        return tokenContract.estimateGas.approve(
          spender,
          amountToApprove.raw.toString()
        );
      });

    // eslint-disable-next-line consistent-return
    return callWithGasPrice(
      tokenContract,
      "approve",
      [spender, useExact ? amountToApprove.raw.toString() : MaxUint256],
      {
        gasLimit: calculateGasMargin(estimatedGas),
      }
    )
      .then((response) => {
        addTransaction(response, {
          summary: `Approve ${token}`,
          approval: { tokenAddress: token, spender },
        });
      })
      .catch((error) => {
        console.error("Failed to approve token", error);
        throw error;
      });
  }, [
    approvalState,
    token,
    tokenContract,
    amountToApprove,
    spender,
    addTransaction,
    callWithGasPrice,
  ]);

  return [approvalState, approve];
}
