/* global BigInt */
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import heroButtonBg from "../../assets/default/Frame-1.png";

// Modal.setAppElement("#yourAppElement");
import { BigNumber, ethers } from "ethers";
import { formatUnits, parseUnits } from "ethers/lib/utils";

import {
  LaunchpadAddress,
  GovernanceTokenAddress,
  GovernanceTokenDecimals,
} from "../../config";
import LaunchPadABI from "../../config/abi/launchpad.json";
import Loader from "../Loader/Loader";
import {
  useApproveCallback,
  ApprovalState,
} from "../../hooks/useApproveCallback";
import {
  useTotalStakingBalance,
  useTierLevelForPool,
} from "../../hooks/contractReader/useLaunchpadInfo";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";

Modal.setAppElement("#root");

function BidPoolModal({
  poolId,
  isModelOpen,
  tokenDecimals,
  closeModalFn,
  pool,
}) {
  function afterOpenModal() {}

  function closeModal() {
    closeModalFn();
  }

  const { account } = useActiveWeb3React();

  const stakingBalanceInfo = useTotalStakingBalance(account);

  const totalStakingBalance = formatUnits(
    stakingBalanceInfo ?? 0,
    GovernanceTokenDecimals
  );

  const tierLevel = useTierLevelForPool(poolId, account);
  const poolTierTarget = formatUnits(
    pool && pool?.targetAmountEveryTier[parseInt(tierLevel ?? 0)]
      ? pool?.targetAmountEveryTier[parseInt(tierLevel ?? 0)]
      : 0,
    GovernanceTokenDecimals
  );
  const poolTierInvested = formatUnits(
    pool && pool?.investedAmountEveryTier[parseInt(tierLevel ?? 0)]
      ? pool?.investedAmountEveryTier[parseInt(tierLevel ?? 0)]
      : 0,
    GovernanceTokenDecimals
  );

  const [bidAmount, setBidAmount] = useState(1);
  const [showLoading, setShowLoading] = useState(false);

  const validateFields = () => {
    if (bidAmount > parseFloat(totalStakingBalance)) return false;
    return true;
  };

  const bidHandle = async () => {
    setShowLoading(true);

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const launchpadContract = new ethers.Contract(
      LaunchpadAddress,
      LaunchPadABI,
      signer
    );

    try {
      let transanction = await launchpadContract.bid(
        poolId.toString(),
        ethers.utils.parseUnits(bidAmount.toString(), "ether")
      );
      await transanction.wait();
    } catch (err) {
      console.log(err);
    }

    setShowLoading(false);
    closeModal();
  };

  return (
    <div className="">
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={isModelOpen}
        onAfterOpen={afterOpenModal}
        className={"scrollable-modal"}
        onRequestClose={closeModal}
        style={{
          content: {
            top: "50%",
            width: "40%",
            padding: "20px",
            position: "absolute",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        contentLabel="Example Modal"
      >
        <div className="custom-modal-content" style={{ minHeight: "34vh" }}>
          {showLoading ? (
            <Loader />
          ) : (
            <div className="form-form">
              <div className="mb-0  ">
                <label
                  className="orionis text-xs"
                  style={{ color: "var(--text-color)" }}
                >
                  Your Staking Amount: {totalStakingBalance}
                </label>
                <br />
                <label
                  className="orionis text-xs"
                  style={{ color: "var(--text-color)" }}
                >
                  Available Investment amount:{" "}
                  {parseFloat(poolTierTarget) - parseFloat(poolTierInvested)}
                </label>
                <br />
                <label
                  className="orionis text-xs"
                  style={{ color: "var(--text-color)" }}
                >
                  Your Tier Level {parseInt(tierLevel ?? 0) + 1} Info :{" "}
                  {poolTierInvested} / {poolTierTarget}
                </label>
                <br />
                <br />
                <input
                  value={bidAmount}
                  onChange={(e) => setBidAmount(e.target.value)}
                  className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                  style={{ backgroundColor: "var(--bg-color)" }}
                  type="number"
                  placeholder="Type your answer here"
                />
              </div>
              <div className="mt-6 w-full text-left">
                <button
                  style={{
                    backgroundImage: `url(${heroButtonBg})`,
                    backgroundSize: "100% 100%",
                    color: "var(--text-color)",
                    float: "right",
                  }}
                  onClick={bidHandle}
                  disabled={!validateFields()}
                  className="quest bg-no-repeat px-6 py-3 max-w-full w-52"
                >
                  Bid
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default BidPoolModal;
