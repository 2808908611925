import React from "react";
import claimButtonBG from "../../assets/images/claimButtonBG.png";
import "./button.css";

const Button = ({ isAvailable, claimHandle }) => {
  return (
    <button
      style={{
        backgroundImage: `url(${claimButtonBG})`,
        backgroundSize: "100% 100%",
        color: "var(--text-color)",
      }}
      onClick={claimHandle}
      disabled={!isAvailable}
      className="scotia unavailableBtn p-3 bg-cover bg-no-repeat px-6 py-3"
    >
      {isAvailable ? "Available" : "Unavailable"}
    </button>
    // <div
    //   className="bg-no-repeat text-center mx-auto w-fit py-4 px-12 load-more-btn"
    //   style={{
    //     background: `url(${heroButtonBg})`,
    //     backgroundSize: "100% 100%",
    //   }}
    // >
    //   <button
    //     type="button"
    //     style={{
    //       color: "var(--text-color)"
    //   }}
    //     className="uppercase font-bold text-sm"
    //   >
    //     Unavailable
    //   </button>
    // </div>
  );
};

export default Button;
