import { useCallback, useMemo, useState } from "react";
import useActiveWeb3React from "./useActiveWeb3React";
import { calculateGasMargin } from "../utils";
import { getFarmContract } from "../utils";
import { useGasPrice } from "../state/user/hooks";
import { useTransactionAdder } from "../state/transactions/hooks";

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useStakeFarm() {
  const { chainId, library, account } = useActiveWeb3React();
  const gasPrice = useGasPrice();
  const addTransaction = useTransactionAdder();

  return useCallback(async (type, amount) => {
    const farmContract = getFarmContract(library, account);
    const estimate =
      type === 0
        ? farmContract.estimateGas.stake
        : type === 1
        ? farmContract.estimateGas.withdraw
        : farmContract.estimateGas.claimRewards;
    const method =
      type === 0
        ? farmContract.stake
        : type === 1
        ? farmContract.withdraw
        : farmContract.claimRewards;

    const args = type !== 2 ? [amount] : [];
    const value = null;

    await estimate(...args, value ? { value } : {})
      .then((estimatedGasLimit) => {
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit),
          gasPrice,
        }).then((response) => {
          // setAttemptingTxn(false);

          addTransaction(response, {
            summary: type === 0 ? "Deposit" : type === 1 ? "Withdraw" : "Claim",
          });
        });
      })
      .catch((err) => {
        // setAttemptingTxn(false);
        // we only care if the error is something _other_ than the user rejected the tx
        if (err?.code !== 4001) {
          console.error(err);
        }
      });
  });
}
