/* global BigInt */
import React, { useState } from "react";
import Modal from "react-modal";
import heroButtonBg from "../../assets/default/Frame-1.png";

// Modal.setAppElement("#yourAppElement");
import { ethers } from "ethers";

import { LaunchpadAddress } from "../../config";
import LaunchPadABI from "../../config/abi/launchpad.json";
import Loader from "../Loader/Loader";
import { parseUnits } from "ethers/lib/utils";
import { useTokenDecimals } from "../../hooks/contractReader/useTokenInfo";

Modal.setAppElement("#root");

function EditPoolModal({ poolId, isModelOpen, closeModalFn }) {
  function afterOpenModal() {}

  function closeModal() {
    closeModalFn();
  }

  const [tokenAddress, setTokenAddress] = useState("");
  const [tokenPrice, setTokenPrice] = useState("");
  const [tokenTotalSupply, setTokenTotalSupply] = useState("");
  // const [bidStartTime, setBidStartTime] = useState("");
  const [bidEndTime, setBidEndTime] = useState("");
  const [minAllocation, setMinAllocation] = useState("0.01");
  const [tierAllowance, setTierAllowance] = useState([]);
  const [accessType, setAccessType] = useState("public");
  const [showLoading, setShowLoading] = useState(false);

  const tokenDecimals = useTokenDecimals(tokenAddress);

  const validateFields = () => {
    if (
      !tokenAddress ||
      !tokenPrice ||
      !tokenTotalSupply ||
      // !bidStartTime ||
      !bidEndTime ||
      !minAllocation ||
      !accessType ||
      !tierAllowance[0] ||
      !tierAllowance[1] ||
      !tierAllowance[2] ||
      !tierAllowance[3] ||
      !tierAllowance[4] ||
      !tierAllowance[5] ||
      !tierAllowance[6] ||
      !tierAllowance[7]
    )
      return false;
    return true;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setShowLoading(true);

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const launchpadContract = new ethers.Contract(
      LaunchpadAddress,
      LaunchPadABI,
      signer
    );

    //add address validation
    try {
      const tier = tierAllowance.map((item) => parseUnits(item, tokenDecimals));
      let transanction = await launchpadContract.modifyPoolInfo(
        poolId.toString(),
        tokenAddress.toString(),
        tokenPrice.toString(),
        tokenDecimals,
        parseUnits(tokenTotalSupply, tokenDecimals),
        // new Date(bidStartTime).getTime().toString(),
        new Date(bidEndTime).getTime().toString(),
        tier
      );
      await transanction.wait();
    } catch (err) {
      // setShowLoading(false);
    }

    setShowLoading(false);
    closeModal();
  }

  const handleTierAllowance = (index, e) => {
    const tier = tierAllowance;
    tier[index] = e.target.value;
    setTierAllowance(tier);
  };

  return (
    <div>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={isModelOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            width: "60%",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        contentLabel="Example Modal"
      >
        {/* {showLoading && <Loader />} */}
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Update</h2> */}
        <div className="custom-modal-content" style={{ minHeight: "80vh" }}>
          {showLoading ? (
            <Loader />
          ) : (
            <form className="form-form" onSubmit={handleSubmit}>
              <div className="mb-0">
                <label
                  className="orionis text-xs"
                  style={{ color: "var(--text-color)" }}
                >
                  Token Address: *
                </label>
                <input
                  value={tokenAddress}
                  onChange={(e) => setTokenAddress(e.target.value)}
                  className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                  style={{ backgroundColor: "var(--bg-color)" }}
                  type="text"
                  placeholder="Type your answer here"
                />
              </div>
              <div className="mb-0">
                <label
                  className="orionis text-xs"
                  style={{ color: "var(--text-color)" }}
                >
                  Token Price : *
                </label>
                <input
                  value={tokenPrice}
                  onChange={(e) => setTokenPrice(e.target.value)}
                  className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                  style={{ backgroundColor: "var(--bg-color)" }}
                  type="text"
                  placeholder="Type your answer here"
                />
              </div>
              <div className="mb-0">
                <label
                  className="orionis text-xs"
                  style={{ color: "var(--text-color)" }}
                >
                  Token Total Supply : *
                </label>
                <input
                  value={tokenTotalSupply}
                  onChange={(e) => setTokenTotalSupply(e.target.value)}
                  className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                  style={{ backgroundColor: "var(--bg-color)" }}
                  type="text"
                  placeholder="Type your answer here"
                />
              </div>
              {/* <div className="mb-0">
                <label
                  className="orionis text-xs"
                  style={{ color: "var(--text-color)" }}
                >
                  Bid Start Time : *
                </label>
                <input
                  value={bidStartTime}
                  onChange={(e) => setBidStartTime(e.target.value)}
                  className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                  style={{ backgroundColor: "var(--bg-color)" }}
                  type="datetime-local"
                  placeholder="Type your answer here"
                />
              </div> */}
              <div className="mb-0">
                <label
                  className="orionis text-xs"
                  style={{ color: "var(--text-color)" }}
                >
                  Bid End Time :
                </label>
                <input
                  value={bidEndTime}
                  onChange={(e) => setBidEndTime(e.target.value)}
                  className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                  style={{ backgroundColor: "var(--bg-color)" }}
                  type="datetime-local"
                  placeholder="Upload your file here"
                />
              </div>
              <div className="mb-0">
                <label
                  className="orionis text-xs"
                  style={{ color: "var(--text-color)" }}
                >
                  Min Allocation : *
                </label>
                <input
                  value={minAllocation}
                  onChange={(e) => setMinAllocation(e.target.value)}
                  className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                  style={{ backgroundColor: "var(--bg-color)" }}
                  type="number"
                  placeholder="Type your answer here"
                />
              </div>
              <div className="mb-0">
                <label
                  className="orionis text-xs"
                  style={{ color: "var(--text-color)" }}
                >
                  Tier one allowance : *
                </label>
                <input
                  value={tierAllowance[0]}
                  onChange={(e) => handleTierAllowance(0, e)}
                  className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                  style={{ backgroundColor: "var(--bg-color)" }}
                  type="number"
                  placeholder="Type your answer here"
                />
              </div>
              <div className="mb-0">
                <label
                  className="orionis text-xs"
                  style={{ color: "var(--text-color)" }}
                >
                  Tier two allowance : *
                </label>
                <input
                  value={tierAllowance[1]}
                  onChange={(e) => handleTierAllowance(1, e)}
                  className="orionis  w-full  p-3 rounded-md text-gray-400 text-sm"
                  style={{ backgroundColor: "var(--bg-color)" }}
                  type="number"
                  placeholder="Type your answer here"
                />
              </div>
              <div className="mb-0">
                <label
                  className="orionis text-xs"
                  style={{ color: "var(--text-color)" }}
                >
                  Tier three allowance : *
                </label>
                <input
                  value={tierAllowance[2]}
                  onChange={(e) => handleTierAllowance(2, e)}
                  className="orionis  w-full  p-3 rounded-md text-gray-400 text-sm"
                  style={{ backgroundColor: "var(--bg-color)" }}
                  type="number"
                  placeholder="Type your answer here"
                />
              </div>
              <div className="mb-0">
                <label
                  className="orionis text-xs"
                  style={{ color: "var(--text-color)" }}
                >
                  Tier four allowance : *
                </label>
                <input
                  value={tierAllowance[3]}
                  onChange={(e) => handleTierAllowance(3, e)}
                  className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                  style={{ backgroundColor: "var(--bg-color)" }}
                  type="number"
                  placeholder="Type your answer here"
                />
              </div>
              <div className="mb-0">
                <label
                  className="orionis text-xs"
                  style={{ color: "var(--text-color)" }}
                >
                  Tier five allowance : *
                </label>
                <input
                  value={tierAllowance[4]}
                  onChange={(e) => handleTierAllowance(4, e)}
                  className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                  style={{ backgroundColor: "var(--bg-color)" }}
                  type="number"
                  placeholder="Type your answer here"
                />
              </div>
              <div className="mb-0">
                <label
                  className="orionis text-xs"
                  style={{ color: "var(--text-color)" }}
                >
                  Tier six allowance : *
                </label>
                <input
                  value={tierAllowance[5]}
                  onChange={(e) => handleTierAllowance(5, e)}
                  className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                  style={{ backgroundColor: "var(--bg-color)" }}
                  type="number"
                  placeholder="Type your answer here"
                />
              </div>
              <div className="mb-0">
                <label
                  className="orionis text-xs"
                  style={{ color: "var(--text-color)" }}
                >
                  Tier seven allowance : *
                </label>
                <input
                  value={tierAllowance[6]}
                  onChange={(e) => handleTierAllowance(6, e)}
                  className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                  style={{ backgroundColor: "var(--bg-color)" }}
                  type="number"
                  placeholder="Type your answer here"
                />
              </div>
              <div className="mb-0">
                <label
                  className="orionis text-xs"
                  style={{ color: "var(--text-color)" }}
                >
                  Tier eight allowance : *
                </label>
                <input
                  value={tierAllowance[7]}
                  onChange={(e) => handleTierAllowance(7, e)}
                  className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
                  style={{ backgroundColor: "var(--bg-color)" }}
                  type="number"
                  placeholder="Type your answer here"
                />
              </div>
              <div className="mb-0 mt-2  ">
                <label
                  className="orionis text-xs"
                  style={{ color: "var(--text-color)" }}
                >
                  Access Type : *
                </label>
                <br />
                <div className="flex">
                  <div className="flex items-center">
                    {" "}
                    <input
                      type="radio"
                      name="accessType"
                      id="private"
                      onChange={(e) => setAccessType(e.target.value)}
                      value="Private"
                      checked={accessType && accessType === "Private"}
                    />
                    &nbsp;
                    <label
                      className="orionis text-xs"
                      style={{ color: "var(--text-color)" }}
                      htmlFor="private"
                    >
                      Private
                    </label>
                  </div>
                  &nbsp; &nbsp;
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="accessType"
                      onChange={(e) => setAccessType(e.target.value)}
                      value="Public"
                      id="public"
                      checked={accessType && accessType === "Public"}
                    />
                    &nbsp;
                    <label
                      className="orionis text-xs"
                      style={{ color: "var(--text-color)" }}
                      htmlFor="public"
                    >
                      Public
                    </label>
                  </div>
                </div>
                {/* <input
              value={accessType}
              onChange={(e) => setAccessType(e.target.value)}
              className="orionis w-full p-3 rounded-md text-gray-400 text-sm"
              style={{ backgroundColor: "var(--bg-color)" }}
              type="number"
              placeholder="Type your answer here"
            /> */}
              </div>
              <div className="mt-4 w-full text-left">
                <button
                  style={{
                    backgroundImage: `url(${heroButtonBg})`,
                    backgroundSize: "100% 100%",
                    color: "var(--text-color)",
                  }}
                  // onClick={onSubmit}
                  type="submit"
                  disabled={!validateFields()}
                  className="quest bg-no-repeat px-6 py-3 max-w-full w-52"
                >
                  Update
                </button>
              </div>{" "}
            </form>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default EditPoolModal;
