import { useSelector } from "react-redux";
import useInterval from "../../hooks/useInterval";
import useIsWindowVisible from "../../hooks/useIsWindowVisible";
import { useAppDispatch } from "../index";
import { simpleRpcProvider } from "../../utils/providers";
import { setBlock } from ".";

export const usePollBlockNumber = (refreshTime = 6000) => {
  const dispatch = useAppDispatch();
  const isWindowVisible = useIsWindowVisible();

  useInterval(
    () => {
      const fetchBlock = async () => {
        const blockNumber = await simpleRpcProvider.getBlockNumber();
        dispatch(setBlock(blockNumber));
      };

      fetchBlock();
    },
    isWindowVisible ? refreshTime : null,
    true
  );
};

export const useBlock = () => {
  return useSelector((state) => state.block);
};

export const useInitialBlock = () => {
  return useSelector((state) => state.block.initialBlock);
};
