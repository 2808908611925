import CarouselContainer from "../components/Hero/CarouselContainer";
import heroButtonBg from "../assets/default/Frame-1.png";
import "./hero.css";
import buttonsBg from "../assets/default/Frame-22.png";

import CharacterizedPools from "./CharacterizedPools";
import Tcard from "../components/tcard/Tcard";

const Hero = ({ currentTime, tcard, cardsArray }) => {
  return (
    <>
      <div className="body-bg-top">
        <div className="w-6/12  sm:w-11/12 m-auto">
          <CarouselContainer />
          <div
            data-aos="fade-up"
            className="carouselContainerCards1 relative mt-10 py-14  z-5 px-5 bg-no-repeat"
            style={{
              backgroundImage: `url(${buttonsBg})`,
              backgroundSize: "100% 100%",
            }}
          >
            <div className="carouselContainerCards grid grid-cols-4 items-center text-center gap-10">
              {cardsArray &&
                cardsArray.map((card, index) => (
                  // <div
                  // className="ContainerCards bg-no-repeat pt-6 pb-4 px-5"

                  // key={card.text}
                  // >
                  <a
                    className="quest ContainerCards bg-no-repeat pt-6 pb-5 px-5 relative"
                    href={card.href ? card.href : "#"}
                    style={{
                      backgroundImage: `url(${heroButtonBg})`,
                      backgroundSize: "100% 100%",
                      color: "var(--text-color)",
                    }}
                    key={index}
                  >
                    {card.text}
                  </a>
                  //  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      {/* <Cards /> */}
      <div className="body-bg-bottom">
        <div className="w-11/12 sm:w-11/12 m-auto pb-10 white-pb-38">
          <div
            data-aos="fade-up"
            className="tcardContainer grid grid-cols-3 gap-16 items-center my-8 mx-auto"
          >
            {tcard &&
              tcard.map((data, i) => (
                <Tcard
                  id={i}
                  key={i}
                  title={data.title}
                  subtitle={data.title2}
                  desc={data.desc}
                />
              ))}
          </div>
          <div data-aos="fade-top">
            <CharacterizedPools
              heading="Featured Pools"
              poolType="1"
              currentTime={currentTime}
            />
          </div>
          <div data-aos="fade-top">
            <CharacterizedPools
              heading="Upcoming Pools"
              poolType="0"
              currentTime={currentTime}
            />
          </div>
          <div data-aos="fade-top">
            <CharacterizedPools
              heading="Completed Pools"
              poolType="2"
              currentTime={currentTime}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
